.features-section-s2 .feature-grids .grid, .features-section-s3 .feature-grids .grid {
	border-radius: 3px;
  }
  
  .features-section-s2 .feature-grids > .grid:first-child, .features-section-s3 .feature-grids > .grid:first-child {
	background-color: #5447c9;
	-webkit-box-shadow: none;
	box-shadow: none;
  }
  
  .features-section-s2 .feature-grids > .grid:first-child .fi:before, .features-section-s3 .feature-grids > .grid:first-child .fi:before,
  .features-section-s2 .feature-grids > .grid:first-child h3,
  .features-section-s3 .feature-grids > .grid:first-child h3,
  .features-section-s2 .feature-grids > .grid:first-child p,
  .features-section-s3 .feature-grids > .grid:first-child p {
	color: #fff;
  }
  
  .features-section-s2 .feature-grids > .grid:nth-child(2) .fi:before, .features-section-s3 .feature-grids > .grid:nth-child(2) .fi:before {
	color: #2ecc0a;
  }
  
  .features-section-s2 .feature-grids > .grid:nth-child(3) .fi:before, .features-section-s3 .feature-grids > .grid:nth-child(3) .fi:before {
	color: #7633ff;
  }
  
  .features-section-s2 .feature-grids .grid:hover, .features-section-s3 .feature-grids .grid:hover {
	background-color: #5447c9;
	-webkit-box-shadow: none;
	box-shadow: none;
  }
  
  .features-section-s2 .feature-grids .grid:hover .fi:before, .features-section-s3 .feature-grids .grid:hover .fi:before,
  .features-section-s2 .feature-grids .grid:hover h3,
  .features-section-s3 .feature-grids .grid:hover h3,
  .features-section-s2 .feature-grids .grid:hover p,
  .features-section-s3 .feature-grids .grid:hover p {
	color: #fff;
  }
  
  .features-section-s3 .feature-grids > .grid:first-child {
	background-color: #2842b0;
  }
  
  .features-section-s3 .feature-grids > .grid:nth-child(3) .fi:before {
	color: #2842b0;
  }
  
  .features-section-s3 .feature-grids .grid:hover {
	background-color: #2842b0;
  }
  
  .features-section-s3 .feature-grids .grid:hover .fi:before {
	color: #fff;
  }