.video-cta-area {
	text-align: center;
	padding-top: 0;
	position: relative;
	padding-bottom: 100px;
  }
  
  @media (max-width: 991px) {
	.video-cta-area {
	  padding-bottom: 90px;
	}
  }
  
  @media (max-width: 767px) {
	.video-cta-area {
	  padding-bottom: 80px;
	}
  }
  
  .video-cta-area:before {
	content: "";
	background: url("../../images/video-cta-bg.png") center bottom/cover no-repeat local;
	width: 100%;
	height: 740px;
	position: absolute;
	left: 0;
	bottom: 0;
  }
  
  .video-cta-area .img-holder {
	max-width: 770px;
	margin: 0 auto;
	position: relative;
  }
  
  .video-cta-area .img-holder img {
	border-radius: 3px;
  }
  
  .video-cta-area .img-holder .wrap {
	background-color: #fff;
	width: 75px;
	height: 75px;
	line-height: 75px;
	text-align: center;
	border-radius: 50%;
	position: absolute;
	bottom: -37px;
	left: 50%;
	-webkit-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-o-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
	border: none;
  }
  
  .video-cta-area .img-holder .fi:before {
	font-size: 24px;
	font-size: 1.6rem;
	color: #5447c9;
  }
  
  .video-cta-area .content {
	max-width: 680px;
	margin: 85px auto 0;
  }
  
  .video-cta-area .content h2 {
	font-size: 36px;
	font-size: 2.4rem;
	margin: 0 0 0.54em;
	color: #fff;
	position: relative;
  }
  
  @media (max-width: 991px) {
	.video-cta-area .content h2 {
	  font-size: 30px;
	  font-size: 2rem;
	}
  }
  
  @media (max-width: 767px) {
	.video-cta-area .content h2 {
	  font-size: 24px;
	  font-size: 1.6rem;
	}
  }
  
  .video-cta-area .content p {
	font-size: 16px;
	font-size: 1.06667rem;
	color: #fff;
	margin: 0 0 2em;
  }
  
  @media (max-width: 767px) {
	.video-cta-area .content p {
	  font-size: 15px;
	  font-size: 1rem;
	}
  }
  