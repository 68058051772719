.services-section-s2 {
  position: relative;
}

.services-section-s2 .shape {
  background-color: #5447c9;
  width: 100%;
  height: 400px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}

@media (max-width: 991px) {
  .services-section-s2 .shape {
    height: 350px;
  }
}

.services-section-s2 .shape:after {
  content: "";
  background: url("../../images/service-area-shape.png") center center/cover
    no-repeat local;
  width: 100%;
  height: 99px;
  position: absolute;
  left: 0;
  bottom: 0;
}

.services-section-s2 .shape .line {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.services-section-s2 .shape .line span {
  width: 1px;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  position: absolute;
  left: 17%;
}

.services-section-s2 .shape .line > span:nth-child(2) {
  left: 34%;
}

.services-section-s2 .shape .line > span:nth-child(3) {
  left: 51%;
}

.services-section-s2 .shape .line > span:nth-child(4) {
  left: 68%;
}

.services-section-s2 .shape .line > span:nth-child(5) {
  left: 85%;
}

@media (max-width: 500px) {
  .services-section-s2 .service-grids {
    margin-top: 70px;
  }
}

.services-section-s2 .service-grids .grid {
  position: relative;
  padding-left: 75px;
}

.services-section-s2 .service-grids > .grid + .grid {
  margin-top: 50px;
}

.services-section-s2 .service-grids .icon {
  position: absolute;
  left: 0;
  top: -11px;
}

.services-section-s2 .service-grids h3 {
  font-size: 18px;
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0.2em 0 0.6em;
}

.services-section-s2 .service-grids h3 a {
  color: #233d62;
}

.services-section-s2 .service-grids h3 a:hover {
  color: #fc6e36;
}

.services-section-s2 .service-grids .fi:before {
  font-size: 50px;
  font-size: 3.33333rem;
  color: #fc6e36;
}

.services-section-s2 .service-grids p {
  margin: 0;
}

@media (max-width: 1199px) {
  .services-section-s2 .service-grids p {
    font-size: 14px;
    font-size: 0.93333rem;
  }
}

@media screen and (min-width: 992px) {
  .services-section-s2 .right-align {
    text-align: right;
  }
  .services-section-s2 .right-align .grid {
    position: relative;
    padding-right: 75px;
    padding-left: 0;
  }
  .services-section-s2 .right-align .icon {
    position: absolute;
    right: 0;
    left: auto;
    top: -11px;
  }
}

@media (max-width: 991px) {
  .services-section-s2 .right-col {
    margin-top: 50px;
  }
}

@media (max-width: 991px) {
  .services-section-s2 .img-holder {
    max-width: 300px;
    margin: auto;
  }
}

.services-pg-s2-section {
  padding-top: 0 !important;
}

@media screen and (min-width: 992px) {
  .services-pg-s2-section {
    margin-top: -20px !important;
  }
}

@media (max-width: 991px) {
  .services-pg-s2-section {
    margin-top: -30px !important;
  }
}

@media (max-width: 767px) {
  .services-pg-s2-section {
    margin-top: -40px !important;
  }
}
