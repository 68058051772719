.shop-single-section {
}

.shop-single-section .shop-single-slider .slider-for {
  text-align: center;
}

.shop-single-section .shop-single-slider .slider-for img {
  display: inline-block;
}

.shop-single-section .shop-single-slider .slider-nav {
  padding: 0;
  margin-top: 35px;
}

.shop-single-section .shop-single-slider .slider-nav > i {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 100;
}

.shop-single-section .shop-single-slider .slider-nav > i:hover {
  cursor: pointer;
}

.shop-single-section .shop-single-slider .slider-nav .nav-btn-rt {
  left: auto;
  right: 0;
}

.shop-single-section .shop-single-slider .slider-nav .slick-slide {
  text-align: center;
}

.shop-single-section .shop-single-slider .slider-nav .slick-slide img {
  display: inline-block;
}

.shop-single-section .product-details {
  padding: 30px 30px 93px;
  /*** product option ***/
}

@media (max-width: 1199px) {
  .shop-single-section .product-details {
    padding: 40px 30px 85px;
  }
}

@media (max-width: 991px) {
  .shop-single-section .product-details {
    margin-top: 45px;
    padding: 40px 30px;
  }
}

@media (max-width: 767px) {
  .shop-single-section .product-details {
    padding: 0;
  }
}

.shop-single-section .product-details h2 {
  font-size: 28px;
  font-size: 1.86667rem;
  font-weight: 600;
  line-height: 1.4em;
  margin: 0 0 0.33em;
}

@media (max-width: 767px) {
  .shop-single-section .product-details h2 {
    font-size: 22px;
    font-size: 1.46667rem;
  }
}

.shop-single-section .product-details .price {
  font-size: 36px;
  font-size: 2.4rem;
  color: #fc6e36;
  margin: 7px 0 14px;
}

@media (max-width: 991px) {
  .shop-single-section .product-details .price {
    font-size: 30px;
    font-size: 2rem;
  }
}

@media (max-width: 767px) {
  .shop-single-section .product-details .price {
    font-size: 25px;
    font-size: 1.66667rem;
  }
}

.shop-single-section .product-details .price .old {
  font-size: 24px;
  font-size: 1.6rem;
  font-weight: normal;
  color: #a3acbe;
  text-decoration: line-through;
  display: inline-block;
  margin-left: 5px;
}

@media (max-width: 991px) {
  .shop-single-section .product-details .price .old {
    font-size: 20px;
    font-size: 1.33333rem;
  }
}

@media (max-width: 767px) {
  .shop-single-section .product-details .price .old {
    font-size: 18px;
    font-size: 1.2rem;
  }
}

.shop-single-section .product-details p {
  margin-bottom: 1.3em;
}

.shop-single-section .product-details p:last-child {
  margin: 0;
}

.shop-single-section .product-details .product-option {
  margin-top: 45px;
}

.shop-single-section .product-details .product-option .product-row {
  overflow: hidden;
}

.shop-single-section .product-details .product-option .product-row > div {
  height: 35px;
  display: inline-block;
  float: left;
}

.shop-single-section .product-details .product-option .product-row > div + div {
  margin-left: 15px;
}

.shop-single-section .product-details .product-option .product-row > div:first-child {
  width: 85px;
}

.shop-single-section .product-details .product-option .product-row > div:last-child .theme-btn:hover, .shop-single-section .product-details .product-option .product-row > div:last-child .theme-btn-s3:hover, .shop-single-section .product-details .product-option .product-row > div:last-child .theme-btn-s4:hover {
  background: #fc6e36;
  border-color: #fc6e36;
  color: #fff;
}

.shop-single-section .product-details .product-option .theme-btn, .shop-single-section .product-details .product-option .theme-btn-s3, .shop-single-section .product-details .product-option .theme-btn-s4 {
  background-color: transparent;
  color: #333;
  font-size: 14px;
  font-size: 0.93333rem;
  padding: 0 20px;
  height: 35px;
  line-height: 32px;
  outline: 0;
  border: 2px solid #e6e6e6;
}

.shop-single-section .product-details .product-option .theme-btn:after, .shop-single-section .product-details .product-option .theme-btn-s3:after, .shop-single-section .product-details .product-option .theme-btn-s4:after {
  display: none;
}

.shop-single-section .product-details .product-option .theme-btn:hover, .shop-single-section .product-details .product-option .theme-btn-s3:hover, .shop-single-section .product-details .product-option .theme-btn-s4:hover {
  background-color: #fc6e36;
  color: #fff;
  border-color: #fc6e36;
}

@media (max-width: 767px) {
  .shop-single-section .product-details .product-option .theme-btn, .shop-single-section .product-details .product-option .theme-btn-s3, .shop-single-section .product-details .product-option .theme-btn-s4 {
    font-size: 14px;
    font-size: 0.93333rem;
  }
}

.shop-single-section .product-details .product-option .theme-btn:before, .shop-single-section .product-details .product-option .theme-btn-s3:before, .shop-single-section .product-details .product-option .theme-btn-s4:before {
  display: none;
}

.shop-single-section .product-details .product-option .heart-btn i {
  font-size: 15px;
  font-size: 1rem;
}

.shop-single-section .product-details .product-option .product-row > div:last-child .theme-btn, .shop-single-section .product-details .product-option .product-row > div:last-child .theme-btn-s3, .shop-single-section .product-details .product-option .product-row > div:last-child .theme-btn-s4 {
  background-color: #fff;
  font-size: 18px;
  font-size: 1.2rem;
  color: #687693;
  border: 1px solid #e6e6e6;
}

.shop-single-section .product-details #product-count {
  border-radius: 0;
  border: 1px solid #e6e6e6;
}

.shop-single-section .product-details #product-count:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.shop-single-section .product-details .bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-up,
.shop-single-section .product-details .bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down {
  border-radius: 0;
  border-color: #e6e6e6;
}

.shop-single-section .product-details .bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-up:hover,
.shop-single-section .product-details .bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down:hover {
  background-color: #fc6e36;
  color: #fff;
}

.shop-single-section .product-details .product-meta {
  margin-top: 40px;
}

.shop-single-section .product-details .product-meta > div + div {
  margin-top: 8px;
}

.shop-single-section .product-details .product-meta .label {
  font-size: 15;
  font-size: 1rem;
  color: #233d62;
  font-weight: 600;
  padding: 0;
}

.shop-single-section .product-details .product-meta a {
  color: #687693;
}

.shop-single-section .product-info {
  margin-top: 75px;
  /*** tabs ***/
  /*** review form ***/
}

.shop-single-section .product-info h4 {
  font-size: 15px;
  font-size: 1rem;
  margin: 0;
  line-height: 1.7em;
}

.shop-single-section .product-info p {
  margin-bottom: 1.3em;
}

.shop-single-section .product-info .tab-pane p:last-child {
  margin-bottom: 0;
}

.shop-single-section .product-info .nav-tabs {
  border: 0;
}

@media (max-width: 767px) {
  .shop-single-section .product-info .nav-tabs {
    margin-bottom: 20px;
  }
}

.shop-single-section .product-info .nav-tabs li {
  border: 1px solid #dcdcdc;
  margin-right: 1px;
}

.shop-single-section .product-info .nav-tabs li.active a {
  border: 0;
  outline: 0;
}

.shop-single-section .product-info .nav-tabs a {
  font-size: 16px;
  font-size: 1.06667rem;
  font-weight: 600;
  color: #687693;
  border: 0;
  border-radius: 0;
  margin: 0;
  display: block;
  padding: 12px 20px 11px;
}

@media (max-width: 767px) {
  .shop-single-section .product-info .nav-tabs a {
    font-size: 14px;
    font-size: 0.93333rem;
    font-weight: normal;
    padding: 10px 10px 8px;
    text-transform: none;
  }
}

.shop-single-section .product-info .nav-item a:hover,
.shop-single-section .product-info .nav-item a.active{
  background: #fc6e36;
  color: #fff;
}

.shop-single-section .product-info .nav-item a.active,
.shop-single-section .product-info .nav-item li:hover {
  border-color: #fc6e36;
}

.shop-single-section .product-info .tab-content {
  border: 1px solid #dcdcdc;
  padding: 35px 25px;
  margin-top: 0;
}

@media (max-width: 767px) {
  .shop-single-section .product-info .tab-content {
    border: 0;
    padding: 0;
    margin: 0;
  }
}

@media screen and (min-width: 1200px) {
  .shop-single-section .product-info .review-form-wrapper {
    padding-left: 45px;
  }
}

@media (max-width: 991px) {
  .shop-single-section .product-info .review-form {
    margin-top: 45px;
  }
}

.shop-single-section .product-info .review-form h4 {
  margin-bottom: 1.73em;
}

.shop-single-section .product-info .review-form form input,
.shop-single-section .product-info .review-form form textarea {
  background: #fbfbfb;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  height: 50px;
  border: 2px solid #efefef;
}

.shop-single-section .product-info .review-form form input:focus,
.shop-single-section .product-info .review-form form textarea:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.shop-single-section .product-info .review-form form textarea {
  height: 130px;
}

.shop-single-section .product-info .review-form form > div {
  margin-bottom: 27px;
}

.shop-single-section .product-info .review-form form > div:last-child {
  margin-bottom: 0;
}

.shop-single-section .product-info .review-form form .rating-wrapper > div {
  display: inline-block;
}

@media (max-width: 767px) {
  .shop-single-section .product-info .review-form form .rating-wrapper > div {
    display: block;
    float: none !important;
  }
}

.shop-single-section .product-info .review-form form .rating-wrapper > div:last-child {
  float: right;
}

@media (max-width: 767px) {
  .shop-single-section .product-info .review-form form .rating-wrapper > div:last-child {
    margin-top: 20px;
  }
}

.shop-single-section .product-info .review-form form .rating a {
  font-size: 14px;
  color: #cccccc;
  display: inline-block;
  margin-right: 10px;
}

@media (max-width: 767px) {
  .shop-single-section .product-info .review-form form .rating a {
    font-size: 12px;
    margin-right: 5px;
  }
}

.shop-single-section .product-info .review-form form .rating a:last-child {
  margin: 0;
}

.shop-single-section .product-info .review-form form .rating a:hover {
  color: #fc6e36;
}

.shop-single-section .product-info .review-form form .theme-btn, .shop-single-section .product-info .review-form form .theme-btn-s3, .shop-single-section .product-info .review-form form .theme-btn-s4 {
  display: inline-block;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #fc6e36;
}

.shop-single-section .product-info .review-form form .theme-btn:hover, .shop-single-section .product-info .review-form form .theme-btn-s3:hover, .shop-single-section .product-info .review-form form .theme-btn-s4:hover {
  background-color: #fc6e36;
  color: #fff;
}

.shop-single-section .slider-nav .slick-slide:focus {
  outline: none;
}
.handle-counter{
  position: relative;
}

.handle-counter input {
	width: 60px;
	height: 40px;
	background-color: #eeeeee;
	border:0;
	text-align: center;
	font-weight: 500;
}
.lft-cntrs {
  top: -5px;
  right: 0;
  position: absolute;
	width: 35px;
	height: 50px;
	background-color: #eeeeee;
	border-left: 1px solid #777777;
}
.lft-cntrs .btn {
	padding: 0;
	opacity: 1;
	width: 100%;
	background-color: inherit;
	position: relative;
	outline: none;
	cursor: pointer;
	color: #222;
}
.lft-cntrs .counter-minus.btn {
	top: -5px;
}
.lft-cntrs .counter-plus.btn {
	top: 5px;
}
.lft-cntrs .btn.focus, .lft-cntrs .btn:focus {
	-webkit-box-shadow: inherit;
	-moz-box-shadow: inherit;
	-ms-box-shadow: inherit;
	-o-box-shadow: inherit;
	box-shadow: inherit;
	outline: none;
}

.slider-nav .nav-tabs {
  border: none;
  justify-content: center;
}
.slider-nav .nav-tabs .nav-link.active{
  border:none
}
.slider-nav .nav-tabs .nav-link{
  padding: 0;
  padding-right:3px;
  border: none;
}