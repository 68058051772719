.shop-pg-section .shop-grids {
	margin: 0 -15px;
  }
  
  .shop-pg-section .shop-grids .grid {
	width: calc(33.33% - 30px);
	float: left;
	margin: 0 15px 30px;
  }
  
  @media (max-width: 991px) {
	.shop-pg-section .shop-grids .grid {
	  width: calc(50% - 30px);
	}
  }
  
  @media (max-width: 550px) {
	.shop-pg-section .shop-grids .grid {
	  width: calc(100% - 30px);
	  float: none;
	}
  }
  
  .shop-pg-section .grid:hover .cart-details li {
	opacity: 1;
	visibility: visible;
	bottom: 0;
  }
  
  .shop-pg-section .img-cart {
	position: relative;
	overflow: hidden;
  }
  
  .shop-pg-section .cart-details {
	width: 100%;
	position: absolute;
	left: 0;
	bottom: 20px;
	text-align: center;
  }
  
  .shop-pg-section .cart-details ul {
	overflow: hidden;
	display: inline-block;
	list-style-type: none;
  }
  
  .shop-pg-section .cart-details li {
	float: left;
	margin: 7px;
	position: relative;
	bottom: -50px;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	transition: all 0.5s;
  }
  
  .shop-pg-section .cart-details li:last-child {
	-webkit-transition: all 0.5s 0.1s;
	-o-transition: all 0.5s 0.1s;
	transition: all 0.5s 0.1s;
  }
  
  .shop-pg-section .cart-details li a {
	background: #fb4a04;
	width: 50px;
	height: 50px;
	line-height: 53px;
	text-align: center;
	font-size: 15px;
	font-size: 1rem;
	color: #fff;
	border-radius: 50%;
	display: block;
  }
  
  .shop-pg-section .cart-details li a:hover {
	background: #333;
  }
  
  .shop-pg-section .details {
	text-align: center;
	padding: 30px 15px;
  }
  
  .shop-pg-section .details h4 {
	font-size: 18px;
	font-size: 1.2rem;
	margin: 0 0 0.4em;
	font-weight: 600;
  }
  
  @media (max-width: 991px) {
	.shop-pg-section .details h4 {
	  font-size: 16px;
	  font-size: 1.06667rem;
	}
  }
  
  .shop-pg-section .details h4 a {
	color: #233d62;
  }
  
  .shop-pg-section .details h4 a:hover {
	color: #fc6e36;
  }
  
  .shop-pg-section .details del {
	color: #aaa;
	font-weight: 500;
	display: inline-block;
	padding-right: 10px;
  }
  
  @media (max-width: 991px) {
	.shop-pg-section .details del {
	  font-size: 14px;
	  font-size: 0.93333rem;
	}
  }
  
  .shop-pg-section .details .price {
	color: #233d62;
	font-weight: 500;
  }
  
  @media (max-width: 991px) {
	.shop-pg-section .details .price {
	  font-size: 14px;
	  font-size: 0.93333rem;
	}
  }
  
  .shop-pg-section .pagination-wrapper {
	text-align: center;
	clear: both;
  }
  