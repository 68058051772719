.error-404-section {
  text-align: center;
}

@media (max-width: 991px) {
  .error-404-section .error {
    width: 100%;
    float: none;
    text-align: center;
  }
}

.error-404-section .error-message {
  margin-top: 40px;
}

@media (max-width: 991px) {
  .error-404-section .error-message {
    width: 100%;
    float: none;
    text-align: center;
  }
}

.error-404-section .error-message h3 {
  font-size: 24px;
  font-size: 1.6rem;
  margin: 0 0 0.8em;
}

@media (max-width: 767px) {
  .error-404-section .error-message h3 {
    font-size: 20px;
    font-size: 1.33333rem;
  }
}

.error-404-section .error-message p {
  font-size: 18px;
  font-size: 1.2rem;
  margin-bottom: 1.8em;
}

@media (max-width: 767px) {
  .error-404-section .error-message p {
    font-size: 16px;
    font-size: 1.06667rem;
  }
}
