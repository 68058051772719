.faq-funfact-section {
  position: relative;
  padding-top: 0 !important;
}
.faq-funfact-section:before,
.service-section-mid:before {
  content: "";
  background: url("../../images/faq-shape.png") center center/cover no-repeat
    local;
  width: 768px;
  height: 1062px;
  position: absolute;
  left: 0;
  top: 0;
}

.service-section-mid:before {
  left: auto;
  right: 0;
  transform: rotate(180deg);
}

.faq-funfact-section .fun-fact-grids {
  margin-top: 235px;
  position: relative;
}

@media (max-width: 1199px) {
  .faq-funfact-section .fun-fact-grids {
    height: 350px;
    margin-top: 80px;
  }
}

.faq-funfact-section .grid {
  background-color: #fc6e36;
  width: 185px;
  height: 185px;
  line-height: 185px;
  border-radius: 50%;
  text-align: center;
  display: table;
  position: absolute;
}

@media (max-width: 550px) {
  .faq-funfact-section .grid {
    width: 50%;
    float: left;
    position: static;
    border-radius: 0;
  }
}

.faq-funfact-section .grid .info {
  display: table-cell;
  vertical-align: middle;
}

.faq-funfact-section .grid h3 {
  font-size: 42px;
  font-size: 2.8rem;
  color: #fff;
  margin: 0;
}

.faq-funfact-section .grid p {
  font-size: 16px;
  font-size: 1.06667rem;
  color: #fff;
  margin: 0;
}

.faq-funfact-section .fun-fact-grids > .grid:first-child {
  left: 150px;
}

.faq-funfact-section .fun-fact-grids > .grid:nth-child(2) {
  background-color: #5197cf;
  top: 130px;
}

.faq-funfact-section .fun-fact-grids > .grid:nth-child(3) {
  background-color: #c334d2;
  width: 135px;
  height: 135px;
  top: 225px;
  left: 215px;
}

@media (max-width: 550px) {
  .faq-funfact-section .fun-fact-grids > .grid:nth-child(3) {
    width: 50%;
    float: left;
    position: static;
    border-radius: 0;
    height: 185px;
  }
}

.faq-funfact-section .fun-fact-grids > .grid:nth-child(3) h3 {
  font-size: 40px;
  font-size: 2.66667rem;
}

.faq-funfact-section .fun-fact-grids > .grid:nth-child(4) {
  background-color: #2842b0;
  width: 145px;
  height: 145px;
  top: 145px;
  left: 340px;
}

@media (max-width: 550px) {
  .faq-funfact-section .fun-fact-grids > .grid:nth-child(4) {
    width: 50%;
    float: left;
    position: static;
    border-radius: 0;
    height: 185px;
  }
}

.faq-funfact-section .fun-fact-grids > .grid:nth-child(4) h3 {
  font-size: 36px;
  font-size: 2.4rem;
}

.theme-accordion-s1 {
  margin-bottom: 0;
}

.theme-accordion-s1 .panel-default {
  background: #fff;
  border: 0;
  border-radius: 3px;
  -webkit-box-shadow: 0px 4px 25.81px 3.19px rgba(35, 61, 98, 0.06);
  box-shadow: 0px 4px 25.81px 3.19px rgba(35, 61, 98, 0.06);
}

.theme-accordion-s1 .panel-heading {
  background-color: transparent;
  padding: 0;
  border-radius: 0;
}

.theme-accordion-s1 .btn-link:hover {
  text-decoration: none;
}

.theme-accordion-s1 .panel + .panel {
  margin-top: 15px;
}

.theme-accordion-s1 .panel-heading button {
  background-color: #fff;
  font-size: 18px;
  font-size: 1.2rem;
  font-weight: 600;
  color: #233d62;
  display: block;
  padding: 25px 35px;
  position: relative;
}

.services-section-s2 ul {
  list-style: circle;
  margin-left: 20px;
}

@media (max-width: 991px) {
  .theme-accordion-s1 .panel-heading button {
    font-size: 15px;
    font-size: 1rem;
    padding: 25px 25px;
  }
}

@media (max-width: 767px) {
  .theme-accordion-s1 .panel-heading button {
    padding: 25px 15px;
  }
}

.theme-accordion-s1 .panel-heading button:before {
  font-family: "themify";
  content: "\e64b";
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: #d4d8e1;
  border: 2px solid #d4d8e1;
  border-radius: 50%;
  font-size: 13px;
  font-size: 0.86667rem;
  position: absolute;
  top: 23px;
  right: -30px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -ms-transition: all 0.3s;
  transition: all 0.3s;
}

@media (max-width: 991px) {
  .theme-accordion-s1 .panel-heading button:before {
    font-size: 18px;
    font-size: 1.2rem;
    right: 20px;
    width: auto;
    height: auto;
    line-height: auto;
    text-align: center;
    color: #d4d8e1;
    border: 0;
    top: 21px;
  }
}

.theme-accordion-s1 .panel-heading button:focus {
  text-decoration: none;
}

.theme-accordion-s1 .panel-heading .collapsed {
  background-color: #fff;
  color: #233d62;
}

.theme-accordion-s1 .panel-heading + .panel-collapse > .panel-body {
  background-color: #fff;
  border: 0;
  padding: 0 35px 15px;
}
.service-single-faq-sec {
  padding: 100px 0 !important;
}
.fq-s {
  padding-bottom: 100px;
}
@media (max-width: 991px) {
  .theme-accordion-s1 .panel-heading + .panel-collapse > .panel-body {
    padding: 0 25px 10px;
    font-size: 15px;
    font-size: 1rem;
  }
}

@media (max-width: 767px) {
  .theme-accordion-s1 .panel-heading + .panel-collapse > .panel-body {
    padding: 0 15px 8px;
  }
}

.theme-accordion-s1 .panel-heading + .panel-collapse > .panel-body p {
  margin-bottom: 1.3em;
}
