.testimonials-section-s2{
  padding-top: 0!important;
}

.testimonials-section-s2 .testimonial2-thumb-active, .testimonials-section-s3 .testimonial2-thumb-active {
  width: 328px;
  text-align: center;
  margin: auto;
  position: relative;
}

@media (max-width: 767px) {
  .testimonials-section-s2 .testimonial2-thumb-active, .testimonials-section-s3 .testimonial2-thumb-active {
    width: 300PX;
  }
}

.testimonials-section-s2 .testimonial2-thumb-active img, .testimonials-section-s3 .testimonial2-thumb-active img {
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  margin: auto;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
  border-radius: 50%;
  opacity: 0.7;
}

.testimonials-section-s2 .testimonial2-thumb-active .slick-center, .testimonials-section-s3 .testimonial2-thumb-active .slick-center {
  border: 0;
  outline: 0;
}

.testimonials-section-s2 .testimonial2-thumb-active .slick-center img, .testimonials-section-s3 .testimonial2-thumb-active .slick-center img {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

.testimonials-section-s2 .testimonial-content-active, .testimonials-section-s3 .testimonial-content-active {
  max-width: 755px;
  margin: 30px auto 0;
  position: relative;
}

.testimonials-section-s2 .grid > p, .testimonials-section-s3 .grid > p {
  font-size: 19px;
  font-size: 1.26667rem;
}

@media (max-width: 767px) {
  .testimonials-section-s2 .grid > p, .testimonials-section-s3 .grid > p {
    font-size: 17px;
    font-size: 1.13333rem;
  }
}

.testimonials-section-s2 .grid .info h5, .testimonials-section-s3 .grid .info h5 {
  font-size: 16px;
  font-size: 1.06667rem;
  margin: 2em 0 0.3em;
}

.testimonials-section-s2 .grid .info p, .testimonials-section-s3 .grid .info p {
  font-size: 13px;
  font-size: 0.86667rem;
  margin: 0;
  color: #fc6e36;
}

.testimonials-section-s2 .testimonial2-thumb-active button i, .testimonials-section-s3 .testimonial2-thumb-active button i {
  font-size: 14px;
  color: #d4d4d4;
  position: absolute;
  left: 0;
}

.testimonials-section-s2 .testimonial2-thumb-active .slick-next i, .testimonials-section-s3 .testimonial2-thumb-active .slick-next i {
  left: auto;
  right: 0;
}


.test-img img{
  margin: auto;
  border-radius: 50%;
  margin-bottom: 30px;
}

.home-s-3 .testimonials-section-s2{
  padding-top: 100px;
}
.testimonials-section-s3,
.testimonials-section-s2-pg,
.faq-funfact-section-pg{
  padding-top: 100px!important;
}

@media (max-width: 991px) {
  .testimonials-section-s3,
  .testimonials-section-s2-pg,
  .faq-funfact-section-pg {
    padding-top: 90px!important;
  }
}

@media (max-width: 767px) {
  .testimonials-section-s3,
  .testimonials-section-s2-pg,
  .faq-funfact-section-pg{
    padding-top: 80px!important;
  }
}
