.blog-pg-section {
    /*** format-standard ***/
    /*** format-gallery ***/
    /*** format-quote ***/
    /*** format-video ***/
  }
  
  .blog-pg-section .blog-content .post {
    margin-bottom: 100px;
    position: relative;
  }
  
  @media (max-width: 991px) {
    .blog-pg-section .blog-content .post {
      margin-bottom: 70px;
    }
  }
  
  @media (max-width: 767px) {
    .blog-pg-section .blog-content .post {
      margin-bottom: 60px;
    }
  }
  
  .blog-pg-section .blog-content .cat {
    background: #fc6e36;
    font-size: 13px;
    font-weight: 700;
    padding: 10px 15px;
    color: white;
    position: absolute;
    left: 0;
    bottom: -20px;
    border-radius: 0;
    z-index: 2;
  }
  
  .blog-pg-section .entry-media {
    position: relative;
  }
  
  .blog-pg-section .entry-media .cat {
    background: #fc6e36;
    font-size: 13px;
    font-weight: 700;
    padding: 10px 15px;
    color: white;
    position: absolute;
    left: 0;
    bottom: -20px;
    border-radius: 0;
    z-index: 2;
  }
  
  .blog-pg-section .entry-details {
    border-top: 0;
    position: relative;
    z-index: 1;
  }
  
  .blog-pg-section .entry-details .read-more {
    font-weight: 700;
    color: #fc6e36;
    text-transform: uppercase;
  }
  
  .blog-pg-section .entry-meta {
    border-top: 0;
    padding: 53px 0 0;
  }
  
  .blog-pg-section .entry-meta span {
    font-size: 14px;
    font-size: 0.93333rem;
    font-weight: 600;
    color: #687693;
    display: inline-block;
  }
  
  .blog-pg-section .entry-meta > span + span {
    margin-left: 50px;
  }
  
  .blog-pg-section .entry-meta span a {
    color: #687693;
  }
  
  .blog-pg-section .entry-meta span a:hover {
    color: #fc6e36;
  }
  
  .blog-pg-section .entry-meta > span + span {
    position: relative;
  }
  
  .blog-pg-section .entry-meta > span + span:before {
    content: "";
    background-color: #687693;
    width: 22px;
    height: 2px;
    position: absolute;
    left: -36px;
    top: 9px;
  }
  
  .blog-pg-section .post h3 {
    font-size: 28px;
    font-size: 1.86667rem;
    font-weight: 600;
    line-height: 1.2em;
    margin: 0.7em 0 0.7em;
  }
  
  @media (max-width: 991px) {
    .blog-pg-section .post h3 {
      font-size: 25px;
      font-size: 1.66667rem;
    }
  }
  
  @media (max-width: 767px) {
    .blog-pg-section .post h3 {
      font-size: 22px;
      font-size: 1.46667rem;
    }
  }
  
  .blog-pg-section .post h3 a {
    color: #233d62;
  }
  
  .blog-pg-section .post h3 a:hover {
    color: #fc6e36;
  }
  
  .blog-pg-section .post p {
    margin-bottom: 1.8em;
  }
  
  @media (max-width: 991px) {
    .blog-pg-section .post p {
      font-size: 16px;
      font-size: 1.06667rem;
    }
  }
  
  .blog-pg-section .format-standard,
  .blog-pg-section .format-quote {
    border: 2px solid #ebedf1;
  }
  
  .blog-pg-section .format-standard .entry-details,
  .blog-pg-section .format-quote .entry-details {
    padding: 0 35px 35px;
  }
  
  @media (max-width: 500px) {
    .blog-pg-section .format-standard .entry-details,
    .blog-pg-section .format-quote .entry-details {
      padding: 0 20px 35px;
    }
  }
  
  .blog-pg-section .format-standard .entry-details .read-more,
  .blog-pg-section .format-quote .entry-details .read-more {
    font-weight: 700;
    color: #fc6e36;
  }
  
  .blog-pg-section .format-standard .entry-meta,
  .blog-pg-section .format-quote .entry-meta {
    padding: 100px 0 0 35px;
  }
  
  @media (max-width: 500px) {
    .blog-pg-section .format-standard .entry-meta,
    .blog-pg-section .format-quote .entry-meta {
      padding: 100px 0 0 20px;
    }
  }
  
  .blog-pg-section .format-standard .cat,
  .blog-pg-section .format-quote .cat {
    top: 35px;
    bottom: auto;
    left: 35px;
  }
  
  @media (max-width: 500px) {
    .blog-pg-section .format-standard .cat,
    .blog-pg-section .format-quote .cat {
      left: 20px;
    }
  }
  
  .blog-pg-section .format-gallery {
    position: relative;
  }
  
  .blog-pg-section .format-gallery .owl-controls {
    width: 100%;
    margin: 0;
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  
  .blog-pg-section .format-gallery .owl-controls .owl-nav [class*=owl-]:hover {
    background: #fc6e36;
  }
  
  .blog-pg-section .format-gallery .owl-controls .owl-nav [class*=owl-] {
    background: rgba(133, 133, 133, 0.5);
    width: 50px;
    height: 50px;
    line-height: 50px;
    padding: 0;
    margin: 0;
    border-radius: 50%;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    -ms-transition: all 0.3s;
    transition: all 0.3s;
  }
  
  .blog-pg-section .format-gallery .owl-controls .owl-nav .owl-prev,
  .blog-pg-section .format-gallery .owl-controls .owl-nav .owl-next {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  
  .blog-pg-section .format-gallery .owl-controls .owl-nav .owl-prev {
    left: 15px;
  }
  
  .blog-pg-section .format-gallery .owl-controls .owl-nav .owl-next {
    right: 15px;
  }
  
  .blog-pg-section .format-quote {
    background-color: #f9f9f9;
    position: relative;
  }
  
  .blog-pg-section .format-quote:before {
    font-family: "Flaticon";
    content: "\f105";
    font-size: 200px;
    font-size: 13.33333rem;
    color: #ecf3fb;
    margin-left: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  
  .blog-pg-section .format-quote h3,
  .blog-pg-section .format-quote p {
    position: relative;
  }
  
  .blog-pg-section .format-video .video-holder {
    position: relative;
    text-align: center;
  }
  
  .blog-pg-section .format-video .video-holder:before {
    content: "";
    background-color: #233d62;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    -ms-transition: all 0.3s;
    transition: all 0.3s;
    opacity: 0.5;
  }
  
  .blog-pg-section .format-video .video-holder:hover:before {
    opacity: 0.7;
  }
  
  .blog-pg-section .format-video .video-holder .wrap {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background: none;
    border: 2px solid #fff;
    border-radius: 50%;
    width: 80px;
    height: 80px;
  }
  
  .blog-pg-section .format-video .video-holder .fi:before {
    font-size: 40px;
    color: #fff;
  }
  
  @media (max-width: 767px) {
    .blog-pg-section .format-video .video-holder .fi:before {
      font-size: 40px;
      font-size: 4rem;
    }
  }
  
  @media screen and (min-width: 1200px) {
    .blog-pg-left-sidebar .blog-sidebar {
      padding-right: 45px;
      padding-left: 0;
    }
  }
  
  @media screen and (min-width: 1200px) {
    .blog-pg-fullwidth .blog-content {
      padding: 0;
    }
  }

