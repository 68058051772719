
/*--------------------------------------------------------------
#0.4	hero slider
--------------------------------------------------------------*/
.hero-slider {
	width: 100%;
	height: 700px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: relative;
	z-index: 0;
  }
  
  @media (max-width: 991px) {
	.hero-slider {
	  height: 600px;
	}
  }
  
  @media (max-width: 767px) {
	.hero-slider {
	  height: 500px;
	}
  }
  
  .hero-slider .swiper-slide {
	overflow: hidden;
	color: #fff;
  }
  
  .hero-slider .swiper-container,
  .hero-slider .hero-container {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
  }
  
  .hero-slider .slide-inner,
  .hero-slider .hero-inner {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 1;
	background-size: cover;
	background-position: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	text-align: left;
  }
  
  .hero-slider .swiper-button-prev,
  .hero-slider .swiper-button-next {
	background: rgba(255, 255, 255, 0.3);
	width: 60px;
	height: 60px;
	line-height: 60px;
	margin-top: -30px;
	text-align: center;
	border-radius: 60px;
  }
  
  @media (max-width: 767px) {
	.hero-slider .swiper-button-prev,
	.hero-slider .swiper-button-next {
	  display: none;
	}
  }
  
  .hero-slider .swiper-button-prev {
	left: 25px;
  }
  
  .hero-slider .swiper-button-prev:before {
	font-family: "themify";
	content: "\e629";
	font-size: 20px;
	color: #fff;
  }
  
  .hero-slider .swiper-button-next {
	right: 25px;
  }
  
  .hero-slider .swiper-button-next:before {
	font-family: "themify";
	content: "\e628";
	font-size: 20px;
	color: #fff;
  }
  
  .hero-slider .swiper-pagination-bullet {
	width: 15px;
	height: 15px;
	text-align: center;
	line-height: 15px;
	font-size: 12px;
	color: #000;
	opacity: 1;
	background: rgba(0, 0, 0, 0.2);
  }
  
  .hero-slider .swiper-pagination-bullet-active {
	color: #fff;
	background: #007aff;
  }
  
  /*--------------------------------------------------------------
	  #hero-style-1
  --------------------------------------------------------------*/
  .hero-style-1 {
	background-color: #2842b0;
	position: relative;
	height: 100vh;
	overflow: hidden;
	min-height: 850px;
  }
  
  @media (max-width: 991px) {
	.hero-style-1 {
	  height: 600px;
	  min-height: 600px;
	}
  }
  
  @media (max-width: 767px) {
	.hero-style-1 {
	  height: 500px;
	  min-height: 600px;
	}
  }
  
  .hero-style-1 .container {
	position: relative;
  }
  
  .hero-style-1 .rocket-area {
	position: absolute;
	right: 0;
	top: -150px;
  }
  
  @media (max-width: 1199px) {
	.hero-style-1 .rocket-area {
	  max-width: 300px;
	}
  }
  
  @media (max-width: 991px) {
	.hero-style-1 .rocket-area {
	  max-width: 250px;
	  top: -80px;
	}
  }
  
  @media (max-width: 767px) {
	.hero-style-1 .rocket-area {
	  z-index: -1;
	}
  }
  
  .hero-style-1:before {
	content: "";
	background: url("../../images/hero-s1-bg.png") left 100px/100% no-repeat local;
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
  }
  
  .hero-style-1:after {
	content: "";
	background: url("../../images/hero-s1-bottom-shape.png") left bottom/cover no-repeat local;
	width: 100%;
	height: 205px;
	position: absolute;
	left: 0;
	bottom: -70px;
  }
  
  @media (max-width: 991px) {
	.hero-style-1:after {
	  display: none;
	}
  }
  
  .hero-style-1 .line {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
  }
  
  .hero-style-1 .line span {
	width: 1px;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.1);
	position: absolute;
	left: 17%;
  }
  
  .hero-style-1 .line > span:nth-child(2) {
	left: 34%;
  }
  
  .hero-style-1 .line > span:nth-child(3) {
	left: 51%;
  }
  
  .hero-style-1 .line > span:nth-child(4) {
	left: 68%;
  }
  
  .hero-style-1 .line > span:nth-child(5) {
	left: 85%;
  }
  
  .hero-style-1 .slide-title {
	max-width: 580px;
  }
  
  .hero-style-1 .slide-title h2 {
	font-size: 60px;
	font-size: 4rem;
	font-weight: 700;
	color: #fff;
	margin: 0 0 0.6em;
  }
  
  @media (max-width: 991px) {
	.hero-style-1 .slide-title h2 {
	  font-size: 50px;
	  font-size: 3.33333rem;
	}
  }
  
  @media (max-width: 767px) {
	.hero-style-1 .slide-title h2 {
	  font-size: 35px;
	  font-size: 2.33333rem;
	}
  }
  
  .hero-style-1 .slide-text {
	max-width: 660px;
  }
  
  .hero-style-1 .slide-text p {
	font-size: 18px;
	font-size: 1.2rem;
	color: #fff;
	margin: 0 0 2.4em;
  }
  
  @media (max-width: 767px) {
	.hero-style-1 .slide-text p {
	  font-size: 16px;
	  font-size: 1.06667rem;
	}
  }
  
  .hero-style-1 .slide-btns > a:last-child {
	font-size: 16px;
	font-size: 1.06667rem;
	font-weight: 600;
	display: inline-block;
  }
  
  @media (max-width: 767px) {
	.hero-style-1 .slide-btns > a:last-child {
	  font-size: 14px;
	  font-size: 0.93333rem;
	}
  }
  
  .hero-style-1 .slide-btns > a:last-child .fi {
	display: inline-block;
	padding-left: 8px;
	position: relative;
	top: 5px;
  }
  
  .hero-style-1 .slide-btns > a:last-child .fi:before {
	font-size: 30px;
  }
  
  @media (max-width: 991px) {
	.hero-style-1 .slide-btns > a:last-child {
	  margin-left: 10px;
	}
  }
  
  /*--------------------------------------------------------------
	  #hero-style-2
  --------------------------------------------------------------*/
  .hero-style-2, .hero-style-3 {
	background-color: #2842b0;
	background: url("../../images/hero-s2-bg.png") center bottom/cover no-repeat local;
	position: relative;
	height: 100vh;
	overflow: hidden;
  }
  
  @media (max-width: 991px) {
	.hero-style-2, .hero-style-3 {
	  height: 600px;
	}
  }
  
  @media (max-width: 767px) {
	.hero-style-2, .hero-style-3 {
	  height: 500px;
	}
  }
  
  .hero-style-2 .slide-title, .hero-style-3 .slide-title {
	max-width: 580px;
  }
  
  .hero-style-2 .slide-title > span, .hero-style-3 .slide-title > span {
	font-size: 14px;
	font-size: 0.93333rem;
	font-weight: 700;
	color: #fff;
	text-transform: uppercase;
	letter-spacing: 10px;
	position: relative;
  }
  
  .hero-style-2 .slide-title > span:before, .hero-style-3 .slide-title > span:before {
	content: "";
	background-color: #fff;
	width: 50px;
	height: 1px;
	position: absolute;
	left: -75px;
	top: 7px;
  }
  
  .hero-style-2 .slide-title h2, .hero-style-3 .slide-title h2 {
	font-size: 75px;
	font-size: 5rem;
	font-weight: 700;
	color: #fff;
	margin: 0.1em 0 0.5em;
	text-transform: uppercase;
  }
  
  @media (max-width: 991px) {
	.hero-style-2 .slide-title h2, .hero-style-3 .slide-title h2 {
	  font-size: 50px;
	  font-size: 3.33333rem;
	}
  }
  
  @media (max-width: 767px) {
	.hero-style-2 .slide-title h2, .hero-style-3 .slide-title h2 {
	  font-size: 35px;
	  font-size: 2.33333rem;
	}
  }
  
  .hero-style-2 .slide-text, .hero-style-3 .slide-text {
	max-width: 660px;
  }
  
  .hero-style-2 .slide-text p, .hero-style-3 .slide-text p {
	font-size: 18px;
	font-size: 1.2rem;
	color: #fff;
	margin: 0 0 2.4em;
  }
  
  @media (max-width: 767px) {
	.hero-style-2 .slide-text p, .hero-style-3 .slide-text p {
	  font-size: 16px;
	  font-size: 1.06667rem;
	}
  }
  
  .hero-style-2 .slide-btns .video-btn, .hero-style-3 .slide-btns .video-btn {
	font-size: 16px;
	font-size: 1.06667rem;
	color: #fff;
	font-weight: 600;
	display: inline-block;
	margin-left: 50px;
  }
  
  @media (max-width: 767px) {
	.hero-style-2 .slide-btns .video-btn, .hero-style-3 .slide-btns .video-btn {
	  font-size: 14px;
	  font-size: 0.93333rem;
	}
  }
  
  .hero-style-2 .slide-btns .video-btn .fi, .hero-style-3 .slide-btns .video-btn .fi {
	display: inline-block;
	padding-left: 8px;
	position: relative;
	top: 5px;
  }
  
  .hero-style-2 .slide-btns .video-btn .fi:before, .hero-style-3 .slide-btns .video-btn .fi:before {
	font-size: 30px;
  }
  
  @media (max-width: 991px) {
	.hero-style-2 .slide-btns .video-btn, .hero-style-3 .slide-btns .video-btn {
	  margin-left: 10px;
	}
  }
  
  .hero-style-2 .hero-s2-vec, .hero-style-3 .hero-s2-vec {
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	right: 10%;
  }
  
  @media (max-width: 991px) {
	.hero-style-2 .hero-s2-vec, .hero-style-3 .hero-s2-vec {
	  max-width: 400px;
	}
  }
  
  @media (max-width: 767px) {
	.hero-style-2 .hero-s2-vec, .hero-style-3 .hero-s2-vec {
	  display: none;
	}
  }
  
  /*--------------------------------------------------------------
	  #hero-style-3
  --------------------------------------------------------------*/
  .hero-style-3 {
	background: url("../../images/cloud-2.png") 80px 125px/auto no-repeat local;
	background-color: #1d41aa;
  }
  
  .hero-style-3 .slide-title > span {
	display: block;
	margin-bottom: 2em;
  }
  
  .hero-style-3 .slide-title > span:before {
	display: none;
  }
  
  .hero-style-3 .slide-title h2 {
	margin: 0 0 0.44em -5px;
	position: relative;
  }
  
  .hero-style-3 .slide-title h2:before {
	content: "";
	background-color: #fff;
	width: 50px;
	height: 1px;
	position: absolute;
	left: -75px;
	top: 42px;
  }
  
  .hero-style-3 .slide-text {
	max-width: 660px;
  }
  
  .hero-style-3 .slide-text p {
	font-size: 18px;
	font-size: 1.2rem;
	color: #fff;
	margin: 0 0 2em;
  }
  
  @media (max-width: 767px) {
	.hero-style-3 .slide-text p {
	  font-size: 16px;
	  font-size: 1.06667rem;
	}
  }
  
  .hero-style-3 .hero-s3-vec {
	position: absolute;
	top: auto;
	bottom: 0;
	right: 0;
  }
  
  @media (max-width: 991px) {
	.hero-style-3 .hero-s3-vec {
	  max-width: 400px;
	}
  }
  
  @media (max-width: 767px) {
	.hero-style-3 .hero-s3-vec {
	  display: none;
	}
  }
  
  @media screen and (min-width: 992px) {
	.hero-style-3 .container {
	  padding-top: 85px;
	}
  }