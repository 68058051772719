.contact-section-s3 {
  position: relative;
  padding-bottom: 25px;
}
.contact-section-s3 .contact-info {
  margin-top: 150px;
  display: block;
}

@media (max-width: 991px) {
  .contact-section-s3 {
    padding-bottom: 15px;
  }
}

@media screen and (min-width: 992px) {
  .contact-section-s3 .contact-info {
    margin-top: 150px;
  }
}

@media (max-width: 991px) {
  .contact-section-s3 .contact-info {
    margin-bottom: 60px;
    margin-top: 50px;
  }
}

.contact-section-s3 .contact-info li {
  position: relative;
  padding-left: 85px;
}

.contact-section-s3 .contact-info ul > li + li {
  margin-top: 50px;
}

.contact-section-s3 .contact-info .fi {
  position: absolute;
  left: 0;
  top: -10px;
}

.contact-section-s3 .contact-info .fi:before {
  font-size: 50px;
  color: #fc6e36;
}

.contact-section-s3 .contact-info h4 {
  font-size: 18px;
  font-size: 1.2rem;
  font-weight: 600;
  color: #233d62;
  margin: 0 0 0.2em;
}

.contact-section-s3 .contact-info p {
  font-size: 16px;
  font-size: 1.06667rem;
  margin: 0;
}

.contact-section-s3 form input,
.contact-section-s3 form select,
.contact-section-s3 form textarea {
  width: 100%;
  height: 55px;
  padding: 6px 12px;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 0;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

@media (max-width: 991px) {
  .contact-section-s3 form input,
  .contact-section-s3 form select,
  .contact-section-s3 form textarea {
    height: 45px;
  }
}

.contact-section-s3 form input:focus,
.contact-section-s3 form select:focus,
.contact-section-s3 form textarea:focus {
  border-color: #fc6e36 !important;
}
.contact-section-s3 form input:focus {
  border: 1px solid #fc6e36;
}
.contact-section-s3 form p {
  color: #fc6e36;
}
.contact-section-s3 form textarea {
  height: 180px;
}

.contact-section-s3 form {
  margin: 0 -15px;
  overflow: hidden;
}

.contact-section-s3 form ::-webkit-input-placeholder {
  font-style: 15px;
  font-style: normal;
  color: #9d9c9c;
}

.contact-section-s3 form :-moz-placeholder {
  font-style: 15px;
  font-style: normal;
  color: #9d9c9c;
}

.contact-section-s3 form ::-moz-placeholder {
  font-style: 15px;
  font-style: normal;
  color: #9d9c9c;
}

.contact-section-s3 form :-ms-input-placeholder {
  font-style: 15px;
  font-style: normal;
  color: #9d9c9c;
}

.contact-section-s3 form select {
  display: inline-block;
  color: #a9a9a9;
  cursor: pointer;
  opacity: 1;
  padding: 6px 12px;
  font-size: 15px;
  font-size: 1rem;
}

.contact-section-s3 form > div {
  width: calc(100% - 30px);
  float: left;
  margin: 0 0px 30px;
}

.contact-section-s3 form > div.full {
  width: 100%;
}

@media (max-width: 600px) {
  .contact-section-s3 form > div {
    width: calc(100% - 30px);
    float: none;
  }
}

.contact-section-s3 form .fullwidth {
  width: calc(100% - 30px);
  float: none;
  clear: both;
}

.contact-section-s3 form textarea,
.contact-section-s3 form input,
.contact-section-s3 form select {
  background: #fdfdfd;
  border-color: #eaeaea;
}

.contact-section-s3 form button {
  outline: none;
  border: 0;
}

.contact-map-section .contact-map {
  height: 450px;
}

.contact-map-section iframe {
  width: 100%;
  height: 100%;
  border: 0;
  outline: 0;
}
