.page-title {
  background-color: #2842b0;
  width: 100%;
  height: 430px;
  position: relative;
  text-align: center;
}

.page-title:before {
  content: "";
  background: url("../../images/page-title-bg.png") center center/cover no-repeat local;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

@media (max-width: 991px) {
  .page-title {
    height: 300px;
  }
}

.page-title .container {
  height: 100%;
  display: table;
}

@media screen and (min-width: 992px) {
  .page-title .container {
    padding-top: 110px;
  }
}

.page-title .container > .row {
  vertical-align: middle;
  display: table-cell;
}

.page-title h2 {
  font-size: 55px;
  font-size: 3.66667rem;
  font-weight: 900;
  color: #fff;
  margin: -0.2em 0 0.30em;
}

@media (max-width: 991px) {
  .page-title h2 {
    font-size: 45px;
    font-size: 3rem;
  }
}

@media (max-width: 767px) {
  .page-title h2 {
    font-size: 40px;
    font-size: 2.66667rem;
  }
}

.page-title p {
  max-width: 650px;
  margin: 0 auto;
  font-size: 18px;
  font-size: 1.2rem;
  color: #fff;
}

@media (max-width: 991px) {
  .page-title p {
    font-size: 16px;
    font-size: 1.06667rem;
  }
}

@media (max-width: 767px) {
  .page-title p {
    font-size: 15px;
    font-size: 1rem;
  }
}