.partners-section, .partners-section-s2 {
	padding: 0 0 100px;
  }
  
  @media (max-width: 991px) {
	.partners-section, .partners-section-s2 {
	  padding: 0 0 90px;
	}
  }
  
  @media (max-width: 767px) {
	.partners-section, .partners-section-s2 {
	  padding: 0 0 80px;
	}
  }
  
  .partners-section  img, .partners-section-s2  img {
	width: auto;
	margin: 0 auto;
	display: inline-block;
  }
  