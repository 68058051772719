.blog-sidebar {
    /*** search-widget ***/
    /*** about-widget ***/
    /*** category-widget ***/
    /*** recent-post-widget ***/
    /*** tag-widget ***/
  }
  
  @media screen and (min-width: 1200px) {
    .blog-sidebar {
      padding-left: 45px;
    }
  }
  
  @media (max-width: 991px) {
    .blog-sidebar {
      margin-top: 80px;
      max-width: 400px;
    }
  }
  
  @media (max-width: 767px) {
    .blog-sidebar {
      margin-top: 60px;
      max-width: 400px;
    }
  }
  
  .blog-sidebar .widget h3 {
    font-size: 18px;
    font-size: 1.2rem;
    font-weight: 600;
    margin: 0 0 1.3em;
    position: relative;
    text-transform: capitalize;
  }
  
  .blog-sidebar > .widget + .widget {
    margin-top: 65px;
  }
  
  .blog-sidebar .search-widget form div {
    position: relative;
  }
  
  .blog-sidebar .search-widget input {
    background-color: #f8f8f8;
    height: 50px;
    font-size: 16px;
    font-size: 1.06667rem;
    padding: 6px 50px 6px 20px;
    border: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    -ms-transition: all 0.3s;
    transition: all 0.3s;
  }
  
  .blog-sidebar .search-widget input:focus {
    background-color: rgba(252, 110, 54, 0.1);
  }
  
  .blog-sidebar .search-widget form button {
    background: #fc6e36;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 20px;
    font-size: 1.33333rem;
    color: #fff;
    border: 0;
    outline: 0;
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 0;
  }
  
  .blog-sidebar .search-widget form button i {
    font-size: 14px;
  }
  
  .blog-sidebar .about-widget {
    text-align: center;
  }
  
  .blog-sidebar .about-widget .img-holder {
    margin-bottom: 25px;
  }
  
  .blog-sidebar .about-widget .img-holder img {
    border-radius: 50%;
  }
  
  .blog-sidebar .about-widget p {
    font-size: 15px;
    font-size: 1rem;
    margin-bottom: 1.2em;
  }
  
  .blog-sidebar .about-widget a {
    font-size: 14px;
    font-size: 0.93333rem;
    font-weight: bold;
    color: #233d62;
    text-decoration: underline;
  }
  
  .blog-sidebar .about-widget a:hover {
    color: #fc6e36;
  }
  
  .blog-sidebar .about-widget .social ul {
    display: inline-block;
  }
  
  .blog-sidebar .about-widget .social ul li {
    float: left;
  }
  
  .blog-sidebar .about-widget .social ul > li + li {
    margin-left: 15px;
  }
  
  .blog-sidebar .about-widget .social a {
    font-size: 13px;
    color: #687693;
  }
  
  .blog-sidebar .about-widget .social a:hover {
    color: #fc6e36;
  }
  
  .blog-sidebar .category-widget ul {
    list-style: none;
  }
  
  .blog-sidebar .category-widget ul li {
    font-size: 15px;
    font-size: 1rem;
    position: relative;
  }
  
  @media (max-width: 767px) {
    .blog-sidebar .category-widget ul li {
      font-size: 14px;
      font-size: 0.93333rem;
    }
  }
  
  .blog-sidebar .category-widget ul li span {
    position: absolute;
    right: 0;
  }
  
  .blog-sidebar .category-widget ul > li + li {
    margin-top: 12px;
    padding-top: 12px;
    border-top: 1px solid #f3f2f2;
  }
  
  .blog-sidebar .category-widget ul a {
    display: block;
    color: #687693;
    position: relative;
  }
  
  .blog-sidebar .category-widget ul a:hover,
  .blog-sidebar .category-widget ul li:hover:before {
    color: #fc6e36;
  }
  
  .blog-sidebar .recent-post-widget .post {
    overflow: hidden;
  }
  
  .blog-sidebar .recent-post-widget .posts > .post + .post {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid #f3f2f2;
  }
  
  .blog-sidebar .recent-post-widget .post .img-holder {
    width: 68px;
    float: left;
  }
  
  .blog-sidebar .recent-post-widget .post .img-holder img {
    border-radius: 5px;
  }
  
  .blog-sidebar .recent-post-widget .post .details {
    width: calc(100% - 68px);
    float: left;
    padding-left: 20px;
  }
  
  .blog-sidebar .recent-post-widget .post h4 {
    font-family: "caros", sans-serif;
    font-size: 15px;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.3em;
    margin: 0 0 0.3em;
  }
  
  @media (max-width: 1199px) {
    .blog-sidebar .recent-post-widget .post h4 {
      margin: 0;
    }
  }
  
  .blog-sidebar .recent-post-widget .post h4 a {
    display: inline-block;
    color: #233d62;
  }
  
  .blog-sidebar .recent-post-widget .post h4 a:hover {
    color: #fc6e36;
  }
  
  .blog-sidebar .recent-post-widget .post .details .date {
    font-size: 13px;
    font-size: 0.86667rem;
    color: #687693;
  }
  
  .blog-sidebar .tag-widget ul {
    overflow: hidden;
    list-style: none;
  }
  
  .blog-sidebar .tag-widget ul li {
    float: left;
    margin: 0 8px 8px 0;
  }
  
  .blog-sidebar .tag-widget ul li a {
    font-size: 13px;
    font-size: 0.86667rem;
    display: inline-block;
    padding: 7px 14px;
    color: #687693;
    border: 1px solid #efefef;
    border-radius: 50px;
  }
  
  .blog-sidebar .tag-widget ul li a:hover {
    background: #fc6e36;
    color: #fff;
    border-color: #fc6e36;
  }
  
  /**** pagination ****/
  .pagination-wrapper {
    text-align: center;
    margin-top: 60px;
  }
  
  @media (max-width: 991px) {
    .pagination-wrapper {
      text-align: left;
      margin-top: 40px;
    }
  }
  
  .pagination-wrapper .pg-pagination {
    display: inline-block;
    overflow: hidden;
    list-style-type: none;
    text-align: center;
  }
  
  .pagination-wrapper .pg-pagination li {
    float: left;
    margin-right: 10px;
  }
  
  @media (max-width: 767px) {
    .pagination-wrapper .pg-pagination li {
      margin-right: 5px;
    }
  }
  
  .pagination-wrapper .pg-pagination li:last-child {
    margin-right: 0;
  }
  
  .pagination-wrapper .pg-pagination li a {
    background-color: transparent;
    width: 50px;
    height: 50px;
    line-height: 47px;
    font-size: 16px;
    font-size: 1.06667rem;
    font-weight: bold;
    color: #a9b8c4;
    border: 2px solid #f4f4f4;
    display: block;
    border-radius: 0;
  }
  
  @media (max-width: 991px) {
    .pagination-wrapper .pg-pagination li a {
      width: 40px;
      height: 40px;
      line-height: 37px;
      font-size: 15px;
      font-size: 1rem;
    }
  }
  
  .pagination-wrapper .pg-pagination .active a,
  .pagination-wrapper .pg-pagination li a:hover {
    background: #fc6e36;
    border-color: #fc6e36;
    color: #fff;
  }
  
  .pagination-wrapper .pg-pagination .fi:before {
    font-size: 15px;
    font-size: 1rem;
  }
  
  .pagination-wrapper-left {
    text-align: left;
  }
  
  .pagination-wrapper-right {
    text-align: right;
  }
  
  @media screen and (min-width: 1200px) {
    .pagination-wrapper-right {
      padding-right: 50px;
    }
  }
  
  @media (max-width: 991px) {
    .pagination-wrapper-right {
      margin-top: 45px;
      text-align: left;
    }
  }
  

  @media screen and (max-width: 991px) {
    .blog-pg-left-sidebar .blog-sidebar {
      margin-top: 0;
    }
  }

  @media screen and (max-width: 767px) {
    .blog-pg-left-sidebar .blog-sidebar {
      margin-top: 0;
    }
  }