.seo-rank-section, .seo-rank-section-s2 {
	background-color: #2842b0;
	text-align: center;
	padding: 100px 0 160px;
	position: relative;
  }
  
  @media (max-width: 991px) {
	.seo-rank-section, .seo-rank-section-s2 {
	  padding: 90px 0 120px;
	}
  }
  
  @media (max-width: 767px) {
	.seo-rank-section, .seo-rank-section-s2 {
	  padding: 80px 0 100px;
	}
  }
  
  .seo-rank-section .container, .seo-rank-section-s2 .container {
	position: relative;
  }
  
  .seo-rank-section .container .rocket, .seo-rank-section-s2 .container .rocket {
	position: absolute;
	right: 0;
	bottom: -160px;
  }
  
  @media (max-width: 991px) {
	.seo-rank-section .container .rocket, .seo-rank-section-s2 .container .rocket {
	  display: none;
	}
  }
  
  .seo-rank-section:after, .seo-rank-section-s2:after {
	content: "";
	background: url("../../images/cloud.png") center center/cover no-repeat local;
	width: 100%;
	height: 140px;
	position: absolute;
	left: 0;
	bottom: 0;
  }
  
  .seo-rank-section h2, .seo-rank-section-s2 h2 {
	font-size: 36px;
	font-size: 2.4rem;
	color: #fff;
	margin: 0 0 1em;
  }
  
  @media (max-width: 991px) {
	.seo-rank-section h2, .seo-rank-section-s2 h2 {
	  font-size: 30px;
	  font-size: 2rem;
	}
  }
  
  @media (max-width: 767px) {
	.seo-rank-section h2, .seo-rank-section-s2 h2 {
	  font-size: 25px;
	  font-size: 1.66667rem;
	}
  }
  
  .seo-rank-section .analytic-form, .seo-rank-section-s2 .analytic-form {
	max-width: 680px;
	margin: 0 auto;
  }
  
  .seo-rank-section .analytic-form .input-1, .seo-rank-section-s2 .analytic-form .input-1 {
	width: 50%;
	float: left;
  }
  
  @media (max-width: 600px) {
	.seo-rank-section .analytic-form .input-1, .seo-rank-section-s2 .analytic-form .input-1 {
	  width: 100%;
	  float: none;
	}
  }
  
  .seo-rank-section .analytic-form form, .seo-rank-section-s2 .analytic-form form {
	margin-top: 25px;
	position: relative;
  }
  
  .seo-rank-section .analytic-form form .text-lebel, .seo-rank-section-s2 .analytic-form form .text-lebel {
	position: relative;
  }
  
  .seo-rank-section .analytic-form form input, .seo-rank-section-s2 .analytic-form form input {
	width: 100%;
	height: 65px;
	color: #687693;
	border: 0;
	padding: 6px 20px;
	border-radius: 70px;
  }
  
  @media (max-width: 600px) {
	.seo-rank-section .analytic-form form input, .seo-rank-section-s2 .analytic-form form input {
	  height: 45px;
	}
  }
  
  .seo-rank-section .analytic-form form input:focus, .seo-rank-section-s2 .analytic-form form input:focus {
	-webkit-box-shadow: none;
	box-shadow: none;
	border-color: #fc6e36;
  }
  
  .seo-rank-section .analytic-form form .submit, .seo-rank-section-s2 .analytic-form form .submit {
	width: 20%;
	position: absolute;
	right: 1px;
	top: 0;
	z-index: 2;
  }
  
  @media (max-width: 600px) {
	.seo-rank-section .analytic-form form .submit, .seo-rank-section-s2 .analytic-form form .submit {
	  width: 100%;
	  position: static;
	  margin: 15px 0 0;
	  text-align: center;
	}
  }
  
  .seo-rank-section .analytic-form form .submit button, .seo-rank-section-s2 .analytic-form form .submit button {
	background: #fc6e36;
	height: 65px;
	font-weight: 600;
	padding: 15px 25px;
	border: 0;
	outline: 0;
	color: #fff;
	border-radius: 0 70px 70px 0;
  }
  
  @media (max-width: 600px) {
	.seo-rank-section .analytic-form form .submit button, .seo-rank-section-s2 .analytic-form form .submit button {
	  border-radius: 0;
	  height: 50px;
	}
  }
  
  @media screen and (min-width: 401px) {
	.seo-rank-section .analytic-form form .submit .icon, .seo-rank-section-s2 .analytic-form form .submit .icon {
	  display: none;
	}
  }
  
  .seo-rank-section .analytic-form .input-1 input, .seo-rank-section-s2 .analytic-form .input-1 input {
	border-radius: 50px 0 0 50px;
  }
  
  @media (max-width: 600px) {
	.seo-rank-section .analytic-form .input-1 input, .seo-rank-section-s2 .analytic-form .input-1 input {
	  border-radius: 0;
	}
  }
  
  .seo-rank-section .analytic-form .input-2 input, .seo-rank-section-s2 .analytic-form .input-2 input {
	border-radius: 0;
	border-left: 1px solid #f1f1f1;
  }
  
  @media (max-width: 600px) {
	.seo-rank-section .analytic-form .input-2 input, .seo-rank-section-s2 .analytic-form .input-2 input {
	  border-radius: 0;
	}
  }
  
  .seo-rank-section .analytic-form .input-1, .seo-rank-section-s2 .analytic-form .input-1,
  .seo-rank-section .analytic-form .input-2,
  .seo-rank-section-s2 .analytic-form .input-2 {
	width: 40%;
  }
  
  @media (max-width: 600px) {
	.seo-rank-section .analytic-form .input-1, .seo-rank-section-s2 .analytic-form .input-1,
	.seo-rank-section .analytic-form .input-2,
	.seo-rank-section-s2 .analytic-form .input-2 {
	  width: 100%;
	}
  }
  
  .seo-rank-section-s2 {
	background: url('../../images/footer-s3-shape.jpg') center bottom/cover no-repeat local;
	margin-top: 0;
	padding: 130px 0;
  }
  
  @media (max-width: 991px) {
	.seo-rank-section-s2 {
	  padding: 110px 0;
	}
  }
  
  .seo-rank-section-s2:after {
	background-image: none;
  }