.project-single-section {
	padding-bottom: 0px!important;
  }
  
  @media (max-width: 991px) {
	.project-single-section {
	  padding-bottom: 65px;
	}
  }
  
  @media (max-width: 767px) {
	.project-single-section {
	  padding-bottom: 55px;
	}
  }
  
  .project-single-section .project-info {
	max-width: 660px;
	margin-bottom: 40px;
  }
  
  .project-single-section .project-info ul li {
	width: 33.33%;
	float: left;
  }
  
  @media (max-width: 600px) {
	.project-single-section .project-info ul li {
	  width: 50%;
	}
  }
  
  @media (max-width: 400px) {
	.project-single-section .project-info ul li {
	  width: 100%;
	  float: none;
	}
  }
  
  .project-single-section .project-info ul > li {
	margin-bottom: 10px;
  }
  
  .project-single-section .project-info li span {
	color: #233d62;
	font-weight: 600;
  }
  
  .project-single-section .content-area {
	padding-top: 60px;
  }
  
  .project-single-section .content-area h2 {
	font-size: 32px;
	font-size: 2.13333rem;
	margin: 0 0 0.8em;
  }
  
  @media (max-width: 991px) {
	.project-single-section .content-area h2 {
	  font-size: 28px;
	  font-size: 1.86667rem;
	}
  }
  
  @media (max-width: 767px) {
	.project-single-section .content-area h2 {
	  font-size: 25px;
	  font-size: 1.66667rem;
	}
  }
  
  .project-single-section .content-area p {
	margin-bottom: 1.7em;
  }
  
  .project-single-section .challange-solution-section {
	margin: 0 -15px;
	padding-top: 0;
  }
  
  @media (max-width: 991px) {
	.project-single-section .challange-solution-section {
	  padding-top: 0;
	}
  }
  
  @media (max-width: 767px) {
	.project-single-section .challange-solution-section {
	  margin-top: -30px;
	}
  }
  
  .project-single-section .challange-solution-section div {
	width: calc(50% - 30px);
	float: left;
	margin: 0 15px 30px;
  }
  
  @media (max-width: 767px) {
	.project-single-section .challange-solution-section div {
	  width: calc(100% - 30px);
	  float: none;
	  margin: 0 15px 30px;
	}
  }
  
  .project-single-section .challange-solution-section h3 {
	font-size: 20px;
	font-size: 1.33333rem;
	margin: 2em 0 1em;
  }
  
  .project-single-section .challange-solution-section p {
	margin-bottom: 1.4em;
  }
  
  .project-single-section .challange-solution-section ul li {
	position: relative;
	padding-left: 35px;
	font-weight: 500;
	color: #233d62;
	margin-top: 14px;
	position: relative;
  }
  
  .project-single-section .challange-solution-section .icon {
	background-color: rgba(252, 110, 54, 0.15);
	width: 25px;
	height: 25px;
	line-height: 25px;
	text-align: center;
	border-radius: 32px;
	position: absolute;
	left: 0;
	top: -3px;
  }
  
  .project-single-section .challange-solution-section .icon img {
	max-width: 10px;
  }
  
  .project-single-related-project {
	padding-top: 0;
  }