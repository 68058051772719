.blog-section {
	background-color: #f0f8fa;
	position: relative;
	padding: 200px 0 70px!important;
  }
  
  @media (max-width: 991px) {
	.blog-section {
	  padding: 150px 0 50px!important;
	}
  }
  
  @media (max-width: 767px) {
	.blog-section {
	  padding: 100px 0 30px!important;
	}
  }
  
  .blog-section:before {
	content: "";
	background: url("../../images/service-s3-wave.png") center center/cover no-repeat local;
	width: 100%;
	height: 214px;
	position: absolute;
	left: 0;
	top: 0;
  }
  
  .blog-section .blog-grids {
	margin: 0 -15px;
  }
  
  @media (max-width: 767px) {
	.blog-section .blog-grids {
	  margin: 0 -7px;
	}
  }
  
  .blog-section .blog-grids .grid {
	width: calc(33.33% - 30px);
	float: left;
	margin: 0 15px 30px;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-o-transition: all 0.3s;
	-ms-transition: all 0.3s;
	transition: all 0.3s;
	position: relative;
	overflow: hidden;
  }
  
  @media (max-width: 991px) {
	.blog-section .blog-grids .grid {
	  width: calc(50% - 30px);
	  margin: 0 15px 50px;
	}
  }
  
  @media (max-width: 767px) {
	.blog-section .blog-grids .grid {
	  width: calc(50% - 15px);
	  margin: 0 7px 50px;
	}
  }
  
  @media (max-width: 600px) {
	.blog-section .blog-grids .grid {
	  width: calc(100% - 15px);
	  float: none;
	}
  }
  
  .blog-section .grid .entry-media {
	position: relative;
  }
  
  .blog-section .grid .entry-media .date {
	background-color: #2842b0;
	font-size: 14px;
	font-size: 0.93333rem;
	color: #fff;
	padding: 5px 15px;
	position: absolute;
	left: 0;
	bottom: 0;
  }
  
  .blog-section .entry-details {
	padding-top: 35px;
  }
  
  @media (max-width: 991px) {
	.blog-section .entry-details {
	  padding-top: 25px;
	}
  }
  
  .blog-section .entry-details h3 {
	font-size: 20px;
	font-size: 1.33333rem;
	font-weight: 600;
	margin: 0 0 0.8em;
  }
  
  @media (max-width: 991px) {
	.blog-section .entry-details h3 {
	  font-size: 18px;
	  font-size: 1.2rem;
	}
  }
  
  .blog-section .entry-details h3 a {
	color: #233d62;
  }
  
  .blog-section .entry-details h3 a:hover {
	color: #fc6e36;
  }
  
  .blog-section .entry-details .read-more {
	font-size: 14px;
	font-size: 0.93333rem;
	font-weight: 600;
	color: #fc6e36;
	text-transform: uppercase;
  }
  
  .partners-section-s2 {
	padding: 100px 0;
  }
  
  @media (max-width: 991px) {
	.partners-section-s2 {
	  padding: 90px 0;
	}
  }
  
  @media (max-width: 767px) {
	.partners-section-s2 {
	  padding: 80px 0;
	}
  }
  