.services-section-s3, .services-section-s4 {
	background-color: #f0f8fa;
	position: relative;
	padding: 200px 0 55px!important;
  }
  
  @media (max-width: 991px) {
	.services-section-s3, .services-section-s4 {
	  padding: 150px 0 45px!important;
	}
  }
  
  @media (max-width: 767px) {
	.services-section-s3, .services-section-s4 {
	  padding: 100px 0 45px!important;
	}
  }
  
  .services-section-s3:before, .services-section-s4:before {
	content: "";
	background: url("../../images/service-s3-wave.png") center center/cover no-repeat local;
	width: 100%;
	height: 214px;
	position: absolute;
	left: 0;
	top: 0;
  }
  
  .services-section-s3 .service-grids, .services-section-s4 .service-grids {
	margin: 0 -15px;
  }
  
  @media (max-width: 767px) {
	.services-section-s3 .service-grids, .services-section-s4 .service-grids {
	  margin: 0 -7px;
	}
  }
  
  .services-section-s3 .service-grids .grid, .services-section-s4 .service-grids .grid {
	width: calc(33.33% - 30px);
	float: left;
	margin: 0 15px 45px;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-o-transition: all 0.3s;
	-ms-transition: all 0.3s;
	transition: all 0.3s;
	position: relative;
	padding-left: 100px;
  }
  
  @media (max-width: 991px) {
	.services-section-s3 .service-grids .grid, .services-section-s4 .service-grids .grid {
	  width: calc(50% - 30px);
	}
  }
  
  @media (max-width: 767px) {
	.services-section-s3 .service-grids .grid, .services-section-s4 .service-grids .grid {
	  width: calc(50% - 15px);
	  margin: 0 7px 35px;
	  padding-left: 80px;
	}
  }
  
  @media (max-width: 600px) {
	.services-section-s3 .service-grids .grid, .services-section-s4 .service-grids .grid {
	  width: calc(100% - 15px);
	  float: none;
	}
  }
  
  .services-section-s3 .service-grids > .grid:nth-child(2) .fi, .services-section-s4 .service-grids > .grid:nth-child(2) .fi {
	background-color: #d4d6f8;
  }
  
  .services-section-s3 .service-grids > .grid:nth-child(2) .fi:before, .services-section-s4 .service-grids > .grid:nth-child(2) .fi:before {
	color: #644eee;
  }
  
  .services-section-s3 .service-grids > .grid:nth-child(3) .fi, .services-section-s4 .service-grids > .grid:nth-child(3) .fi {
	background-color: #c2dfcd;
  }
  
  .services-section-s3 .service-grids > .grid:nth-child(3) .fi:before, .services-section-s4 .service-grids > .grid:nth-child(3) .fi:before {
	color: #0b7b18;
  }
  
  .services-section-s3 .service-grids > .grid:nth-child(4) .fi, .services-section-s4 .service-grids > .grid:nth-child(4) .fi {
	background-color: #e0e8c9;
  }
  
  .services-section-s3 .service-grids > .grid:nth-child(4) .fi:before, .services-section-s4 .service-grids > .grid:nth-child(4) .fi:before {
	color: #a1a705;
  }
  
  .services-section-s3 .service-grids > .grid:nth-child(6) .fi, .services-section-s4 .service-grids > .grid:nth-child(6) .fi {
	background-color: #efc9d5;
  }
  
  .services-section-s3 .service-grids > .grid:nth-child(6) .fi:before, .services-section-s4 .service-grids > .grid:nth-child(6) .fi:before {
	color: #ea0f42;
  }
  
  .services-section-s3 .grid .fi, .services-section-s4 .grid .fi {
	background-color: #f2dcd3;
	width: 75px;
	height: 75px;
	line-height: 75px;
	text-align: center;
	border-radius: 50%;
	display: block;
	position: absolute;
	left: 0;
	top: 0;
  }
  
  @media (max-width: 767px) {
	.services-section-s3 .grid .fi, .services-section-s4 .grid .fi {
	  width: 65px;
	  height: 65px;
	  line-height: 65px;
	}
  }
  
  .services-section-s3 .grid .fi:before, .services-section-s4 .grid .fi:before {
	font-size: 35px;
	font-size: 2.33333rem;
	color: #fc6e36;
  }
  
  @media (max-width: 767px) {
	.services-section-s3 .grid .fi:before, .services-section-s4 .grid .fi:before {
	  font-size: 30px;
	  font-size: 2rem;
	}
  }
  
  .services-section-s3 .grid h3, .services-section-s4 .grid h3 {
	font-size: 18px;
	font-size: 1.2rem;
	margin: 0 0 0.6em;
	font-weight: 600;
  }
  
  @media (max-width: 767px) {
	.services-section-s3 .grid h3, .services-section-s4 .grid h3 {
	  font-size: 16px;
	  font-size: 1.06667rem;
	}
  }
  
  .services-section-s3 .grid h3 a, .services-section-s4 .grid h3 a {
	color: #233d62;
  }
  
  .services-section-s3 .grid h3 a:hover, .services-section-s4 .grid h3 a:hover {
	color: #fc6e36;
  }
  
  .services-section-s3 .grid p, .services-section-s4 .grid p {
	font-size: 15px;
	font-size: 1rem;
	margin: 0;
  }
  
  @media (max-width: 767px) {
	.services-section-s3 .grid p, .services-section-s4 .grid p {
	  font-size: 14px;
	  font-size: 0.93333rem;
	}
  }
  
  .services-section-s3-page,
  .testimonials-pg-section,
  .testimonials-section-s2-pg,
  .faq-funfact-section-pg {
	background: #fff;
	padding-top: 100px!important;
  }
  
  @media (max-width: 991px) {
	.services-section-s3-page,
	.testimonials-pg-section,
	.testimonials-section-s2-pg,
	.faq-funfact-section-pg {
	  padding-top: 90px!important;
	}
  }
  
  @media (max-width: 767px) {
	.services-section-s3-page,
	.testimonials-pg-section,
	.testimonials-section-s2-pg,
	.faq-funfact-section-pg {
	  padding-top: 80px!important;
	}
  }
  