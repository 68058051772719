.testimonials-section {
	padding-bottom: 80px;
  }
  
  @media (max-width: 991px) {
	.testimonials-section {
	  padding-bottom: 60px;
	}
  }
  
  @media (max-width: 767px) {
	.testimonials-section {
	  padding-bottom: 70px;
	}
  }
  
  @media screen and (min-width: 1200px) {
	.testimonials-section .testimonials-area {
	  padding-left: 25px;
	}
  }
  
  @media (max-width: 991px) {
	.testimonials-section .testimonials-area {
	  margin-top: 50px;
	}
  }
  
  .testimonials-section .quote p {
	font-size: 18px;
	font-size: calc-rem-vlue(18);
	margin: 0;
  }
  
  .testimonials-section .client-info {
	margin-top: 35px;
  }
  
  .testimonials-section .client-info h5 {
	font-size: 16px;
	font-size: 1.06667rem;
	margin: 0 0 0.5em;
  }
  
  .testimonials-section .client-info p {
	font-size: 14px;
	font-size: 0.93333rem;
	margin: 0;
  }
  
  .testimonials-section .owl-controls {
	margin-top: 0;
	position: absolute;
	bottom: 0;
	left: -5px;
  }
  
  .testimonials-section .owl-carousel .owl-stage-outer .owl-item {
	padding-bottom: 40px;
  }

  .testimonials-section .slick-dots {
    bottom: -50px;
    text-align: left;
}

.slick-dots li button{
    background-color: #fc6e36;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
	-o-border-radius: 50%;
	transition: all .3s;
}
.slick-dots li button {
	width: 10px;
	height: 10px;
	background-color: #fc6e36;
	opacity: .5;
}
.slick-dots li.slick-active button {
    width: 25px;
    background-color: #fc6e36;
    border-radius:10px ;
    -webkit-border-radius:10px ;
    -moz-border-radius:10px ;
    -ms-border-radius:10px ;
	-o-border-radius:10px ;
	opacity: 1;
}

.slick-slide {
    outline: none;
    padding: 0 10px;
}
.slick-dots {
	bottom: -31px;
}

.slick-dots li button:before{
    display: none;
}

.slick-dots li {
    margin: 0 10px;
}