.site-footer,
.site-footer-s2,
.site-footer-s3 {
  background: #1d41aa;
  width: 100%;
  position: relative;
  font-size: 15px;
  overflow: hidden;
}

.site-footer ul,
.site-footer-s2 ul,
.site-footer-s3 ul {
  margin: 0;
}

@media (max-width: 991px) {
  .site-footer,
  .site-footer-s2,
  .site-footer-s3 {
    margin-top: 0;
    background-color: #2842b0;
  }
}

.site-footer ul,
.site-footer-s2 ul,
.site-footer-s3 ul {
  list-style: none;
}

.site-footer p,
.site-footer-s2 p,
.site-footer-s3 p,
.site-footer li,
.site-footer-s2 li,
.site-footer-s3 li,
.site-footer a,
.site-footer-s2 a,
.site-footer-s3 a {
  color: #fff;
  margin-bottom: 0;
}

.site-footer .upper-footer,
.site-footer-s2 .upper-footer,
.site-footer-s3 .upper-footer {
  padding: 0 0 90px;
}

@media (max-width: 1199px) {
  .site-footer .upper-footer,
  .site-footer-s2 .upper-footer,
  .site-footer-s3 .upper-footer {
    padding-top: 190px;
  }
}

@media (max-width: 991px) {
  .site-footer .upper-footer,
  .site-footer-s2 .upper-footer,
  .site-footer-s3 .upper-footer {
    padding: 90px 0 20px;
  }
}

@media (max-width: 767px) {
  .site-footer .upper-footer,
  .site-footer-s2 .upper-footer,
  .site-footer-s3 .upper-footer {
    padding: 80px 0 10px;
  }
}

@media (max-width: 991px) {
  .site-footer .upper-footer .col,
  .site-footer-s2 .upper-footer .col,
  .site-footer-s3 .upper-footer .col {
    min-height: 235px;
    margin-bottom: 70px;
  }
}

@media (max-width: 767px) {
  .site-footer .upper-footer .col,
  .site-footer-s2 .upper-footer .col,
  .site-footer-s3 .upper-footer .col {
    min-height: auto;
    margin-bottom: 60px;
  }
}

.site-footer .widget-title,
.site-footer-s2 .widget-title,
.site-footer-s3 .widget-title {
  margin-bottom: 30px;
}

@media (max-width: 767px) {
  .site-footer .widget-title,
  .site-footer-s2 .widget-title,
  .site-footer-s3 .widget-title {
    margin-bottom: 20px;
  }
}

.site-footer .widget-title h3,
.site-footer-s2 .widget-title h3,
.site-footer-s3 .widget-title h3 {
  font-size: 20px;
  font-size: 1.33333rem;
  font-weight: 600;
  color: #fff;
  margin: 0;
  padding-bottom: 0.4em;
  text-transform: capitalize;
  position: relative;
}

@media (max-width: 991px) {
  .site-footer .widget-title h3,
  .site-footer-s2 .widget-title h3,
  .site-footer-s3 .widget-title h3 {
    padding-bottom: 0;
  }
}

.site-footer .about-widget .logo,
.site-footer-s2 .about-widget .logo,
.site-footer-s3 .about-widget .logo {
  max-width: 180px;
}

.site-footer .about-widget p,
.site-footer-s2 .about-widget p,
.site-footer-s3 .about-widget p {
  margin-bottom: 0.8em;
  line-height: 1.9em;
}

.site-footer .about-widget p:last-child,
.site-footer-s2 .about-widget p:last-child,
.site-footer-s3 .about-widget p:last-child {
  margin-bottom: 0;
}

.site-footer .about-widget .social-icons,
.site-footer-s2 .about-widget .social-icons,
.site-footer-s3 .about-widget .social-icons {
  overflow: hidden;
}

@media (max-width: 991px) {
  .site-footer .about-widget .social-icons,
  .site-footer-s2 .about-widget .social-icons,
  .site-footer-s3 .about-widget .social-icons {
    float: none;
    display: block;
    text-align: center;
  }
}

.site-footer .about-widget .social-icons ul,
.site-footer-s2 .about-widget .social-icons ul,
.site-footer-s3 .about-widget .social-icons ul {
  overflow: hidden;
  list-style: none;
  margin-top: 15px;
}

.site-footer .about-widget .social-icons ul li,
.site-footer-s2 .about-widget .social-icons ul li,
.site-footer-s3 .about-widget .social-icons ul li {
  float: left;
}

.site-footer .about-widget .social-icons ul > li + li,
.site-footer-s2 .about-widget .social-icons ul > li + li,
.site-footer-s3 .about-widget .social-icons ul > li + li {
  margin-left: 15px;
}

.site-footer .about-widget .social-icons ul a,
.site-footer-s2 .about-widget .social-icons ul a,
.site-footer-s3 .about-widget .social-icons ul a {
  font-size: 14px;
  font-size: 0.93333rem;
  color: #fff;
}

.site-footer .about-widget .social-icons ul a:hover,
.site-footer-s2 .about-widget .social-icons ul a:hover,
.site-footer-s3 .about-widget .social-icons ul a:hover {
  color: #fc6e36;
}

.site-footer .link-widget,
.site-footer-s2 .link-widget,
.site-footer-s3 .link-widget {
  overflow: hidden;
}

@media screen and (min-width: 1200px) {
  .site-footer .link-widget,
  .site-footer-s2 .link-widget,
  .site-footer-s3 .link-widget {
    padding-left: 20px;
  }
}

@media (max-width: 1199px) {
  .site-footer .link-widget,
  .site-footer-s2 .link-widget,
  .site-footer-s3 .link-widget {
    padding-left: 20px;
  }
}

@media (max-width: 991px) {
  .site-footer .link-widget,
  .site-footer-s2 .link-widget,
  .site-footer-s3 .link-widget {
    padding-left: 0;
  }
}

@media (max-width: 767px) {
  .site-footer .link-widget,
  .site-footer-s2 .link-widget,
  .site-footer-s3 .link-widget {
    max-width: 350px;
  }
}

.site-footer .link-widget ul,
.site-footer-s2 .link-widget ul,
.site-footer-s3 .link-widget ul {
  width: 50%;
  float: left;
}

.site-footer .link-widget ul li,
.site-footer-s2 .link-widget ul li,
.site-footer-s3 .link-widget ul li {
  position: relative;
}

.site-footer .link-widget ul a,
.site-footer-s2 .link-widget ul a,
.site-footer-s3 .link-widget ul a {
  color: #fff;
  font-weight: 500;
}

.site-footer .link-widget ul a:hover,
.site-footer-s2 .link-widget ul a:hover,
.site-footer-s3 .link-widget ul a:hover {
  color: #fc6e36;
}

.site-footer .link-widget ul > li + li,
.site-footer-s2 .link-widget ul > li + li,
.site-footer-s3 .link-widget ul > li + li {
  margin-top: 15px;
}

@media screen and (min-width: 1200px) {
  .site-footer .service-link-widget,
  .site-footer-s2 .service-link-widget,
  .site-footer-s3 .service-link-widget {
    padding-left: 25px;
  }
}

.site-footer .service-link-widget ul,
.site-footer-s2 .service-link-widget ul,
.site-footer-s3 .service-link-widget ul {
  margin-top: 25px;
}

.site-footer .service-link-widget ul li,
.site-footer-s2 .service-link-widget ul li,
.site-footer-s3 .service-link-widget ul li {
  position: relative;
}

.site-footer .service-link-widget ul > li + li,
.site-footer-s2 .service-link-widget ul > li + li,
.site-footer-s3 .service-link-widget ul > li + li {
  margin-top: 15px;
}

.site-footer .service-link-widget a:hover,
.site-footer-s2 .service-link-widget a:hover,
.site-footer-s3 .service-link-widget a:hover {
  color: #fc6e36;
}

.site-footer .newsletter-widget form,
.site-footer-s2 .newsletter-widget form,
.site-footer-s3 .newsletter-widget form {
  margin-top: 25px;
  position: relative;
}

.site-footer .newsletter-widget form input,
.site-footer-s2 .newsletter-widget form input,
.site-footer-s3 .newsletter-widget form input {
  background-color: transparent;
  height: 50px;
  color: #fff;
  padding: 6px 20px;
  border-radius: 0;
  border: 1px solid #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.site-footer .newsletter-widget form ::-webkit-input-placeholder,
.site-footer-s2 .newsletter-widget form ::-webkit-input-placeholder,
.site-footer-s3 .newsletter-widget form ::-webkit-input-placeholder {
  color: #fff;
  opacity: 1;
}

.site-footer .newsletter-widget form :-ms-input-placeholder,
.site-footer-s2 .newsletter-widget form :-ms-input-placeholder,
.site-footer-s3 .newsletter-widget form :-ms-input-placeholder {
  color: #fff;
  opacity: 1;
}

.site-footer .newsletter-widget form ::-ms-input-placeholder,
.site-footer-s2 .newsletter-widget form ::-ms-input-placeholder,
.site-footer-s3 .newsletter-widget form ::-ms-input-placeholder {
  color: #fff;
  opacity: 1;
}

.site-footer .newsletter-widget form ::placeholder,
.site-footer-s2 .newsletter-widget form ::placeholder,
.site-footer-s3 .newsletter-widget form ::placeholder {
  color: #fff;
  opacity: 1;
}

.site-footer .newsletter-widget form :-ms-input-placeholder,
.site-footer-s2 .newsletter-widget form :-ms-input-placeholder,
.site-footer-s3 .newsletter-widget form :-ms-input-placeholder {
  color: #fff;
}

.site-footer .newsletter-widget form ::-ms-input-placeholder,
.site-footer-s2 .newsletter-widget form ::-ms-input-placeholder,
.site-footer-s3 .newsletter-widget form ::-ms-input-placeholder {
  color: #fff;
}

.site-footer .newsletter-widget form .submit,
.site-footer-s2 .newsletter-widget form .submit,
.site-footer-s3 .newsletter-widget form .submit {
  position: absolute;
  right: 20px;
  top: 55%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.site-footer .newsletter-widget form .submit button,
.site-footer-s2 .newsletter-widget form .submit button,
.site-footer-s3 .newsletter-widget form .submit button {
  background: transparent;
  border: 0;
  outline: 0;
  font-size: 20px;
  color: #fff;
  margin-top: -2px;
  position: relative;
  right: -5px;
}

.site-footer .lower-footer,
.site-footer-s2 .lower-footer,
.site-footer-s3 .lower-footer {
  text-align: center;
  position: relative;
}

.site-footer .lower-footer .row,
.site-footer-s2 .lower-footer .row,
.site-footer-s3 .lower-footer .row {
  padding: 20px 0;
  position: relative;
}

.site-footer .lower-footer .row .separator,
.site-footer-s2 .lower-footer .row .separator,
.site-footer-s3 .lower-footer .row .separator {
  background: rgba(255, 255, 255, 0.1);
  width: calc(100% - 30px);
  height: 1px;
  position: absolute;
  left: 15px;
  top: 0;
}

.site-footer .lower-footer .copyright,
.site-footer-s2 .lower-footer .copyright,
.site-footer-s3 .lower-footer .copyright {
  display: inline-block;
  float: left;
  font-size: 14px;
  font-size: 0.93333rem;
  margin: 0;
}

@media (max-width: 991px) {
  .site-footer .lower-footer .copyright,
  .site-footer-s2 .lower-footer .copyright,
  .site-footer-s3 .lower-footer .copyright {
    float: none;
    display: block;
    margin: 0 0 20px;
  }
}

.site-footer .lower-footer .copyright a,
.site-footer-s2 .lower-footer .copyright a,
.site-footer-s3 .lower-footer .copyright a {
  color: #fff;
  text-decoration: underline;
}

.site-footer .lower-footer .extra-link,
.site-footer-s2 .lower-footer .extra-link,
.site-footer-s3 .lower-footer .extra-link {
  display: inline-block;
  float: right;
  overflow: hidden;
}

@media (max-width: 991px) {
  .site-footer .lower-footer .extra-link,
  .site-footer-s2 .lower-footer .extra-link,
  .site-footer-s3 .lower-footer .extra-link {
    float: none;
    display: block;
    text-align: center;
  }
}

.site-footer .lower-footer .extra-link ul,
.site-footer-s2 .lower-footer .extra-link ul,
.site-footer-s3 .lower-footer .extra-link ul {
  overflow: hidden;
  list-style: none;
}

@media (max-width: 991px) {
  .site-footer .lower-footer .extra-link ul,
  .site-footer-s2 .lower-footer .extra-link ul,
  .site-footer-s3 .lower-footer .extra-link ul {
    display: inline-block;
  }
}

.site-footer .lower-footer .extra-link ul li,
.site-footer-s2 .lower-footer .extra-link ul li,
.site-footer-s3 .lower-footer .extra-link ul li {
  float: left;
}

.site-footer .lower-footer .extra-link ul > li + li,
.site-footer-s2 .lower-footer .extra-link ul > li + li,
.site-footer-s3 .lower-footer .extra-link ul > li + li {
  margin-left: 35px;
  position: relative;
}

@media (max-width: 767px) {
  .site-footer .lower-footer .extra-link ul > li + li,
  .site-footer-s2 .lower-footer .extra-link ul > li + li,
  .site-footer-s3 .lower-footer .extra-link ul > li + li {
    margin-left: 15px;
  }
}

.site-footer .lower-footer .extra-link ul > li + li:before,
.site-footer-s2 .lower-footer .extra-link ul > li + li:before,
.site-footer-s3 .lower-footer .extra-link ul > li + li:before {
  content: "";
  background: #fff;
  width: 15px;
  height: 1px;
  position: absolute;
  left: -25px;
  top: 11px;
}

@media (max-width: 767px) {
  .site-footer .lower-footer .extra-link ul > li + li:before,
  .site-footer-s2 .lower-footer .extra-link ul > li + li:before,
  .site-footer-s3 .lower-footer .extra-link ul > li + li:before {
    display: none;
  }
}

.site-footer .lower-footer .extra-link ul a,
.site-footer-s2 .lower-footer .extra-link ul a,
.site-footer-s3 .lower-footer .extra-link ul a {
  font-size: 14px;
  font-size: 0.93333rem;
  color: #fff;
}

.site-footer .lower-footer .extra-link ul a:hover,
.site-footer-s2 .lower-footer .extra-link ul a:hover,
.site-footer-s3 .lower-footer .extra-link ul a:hover {
  text-decoration: underline;
}

.site-footer-s2,
.site-footer-s3 {
  background: url("../../images/footer-shape-s2.png") center top/cover no-repeat
    local;
  margin-top: 0;
  padding-top: 200px;
}

@media (max-width: 1199px) {
  .site-footer-s2,
  .site-footer-s3 {
    padding-top: 0;
  }
}

@media (max-width: 991px) {
  .site-footer-s2,
  .site-footer-s3 {
    padding-top: 0;
    margin-top: 0;
    background-color: #5447c9;
  }
}

.site-footer-s3 {
  background: url("../../images/footer-s3-shape.jpg") center bottom/cover
    no-repeat local;
  margin-top: 0;
  padding-top: 100px;
}

@media (max-width: 1199px) {
  .site-footer-s3 {
    padding-top: 0;
  }
  .site-footer-s3 .upper-footer {
    padding-top: 100px;
  }
}

@media (max-width: 991px) {
  .site-footer-s3 {
    padding-top: 0;
    margin-top: 0;
    background-color: #5447c9;
  }
}

@media (max-width: 767px) {
  .site-footer-s3 {
    padding-top: 0;
  }
  .site-footer-s3 .upper-footer {
    padding-top: 80px;
  }
}

.sticky-header {
  width: 100%;
  position: fixed;
  left: 0;
  top: -200px;
  z-index: 9999;
  opacity: 0;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  -ms-transition: all 0.7s;
  transition: all 0.7s;
}

.sticky-on {
  opacity: 1;
  top: 0;
}

.header-style-1 .sticky-header,
.header-style-2 .sticky-header,
.header-style-3 .sticky-header {
  background-color: #2842b0;
}

.header-style-2 .sticky-header,
.header-style-3 .sticky-header {
  background-color: #5447c9;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.header-style-3 .sticky-header {
  background-color: #2842b0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.about-widget .logo {
  float: none;
  margin-top: 0;
}
