.about-us-section {
  background: url("../../images/about-bg.png") center top/cover no-repeat local;
}

.serviceList {
  list-style: circle;
  margin-left: 10px;
}

.serviceList b {
  font-weight: bold;
}

.about-us-section .about-details h5 {
  font-size: 18px;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.6em;
  margin: 0 0 1em;
}

.about-us-section .about-details p {
  margin-bottom: 2.5em;
}

@media (max-width: 991px) {
  .about-us-section .img-holder {
    margin-top: 50px;
  }
}
