.pricing-section {
	padding-bottom: 70px;
	position: relative;
  }
  
  @media (max-width: 991px) {
	.pricing-section {
	  padding-bottom: 60px;
	}
  }
  
  @media (max-width: 767px) {
	.pricing-section {
	  padding-bottom: 65px;
	}
  }
  
  .pricing-section:before {
	content: "";
	height: 60em;
	width: 65em;
	top: 10%;
	right: -5%;
	background: #f6f5fd;
	border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%;
	position: absolute;
  }
  
  .pricing-section .pricing-grids {
	margin: 0 -15px;
  }
  
  @media (max-width: 767px) {
	.pricing-section .pricing-grids {
	  margin: 0 -7px;
	}
  }
  
  .pricing-section .pricing-grids .grid {
	background-color: #fff;
	width: calc(33.33% - 30px);
	float: left;
	margin: 0 15px 30px;
	padding: 70px 55px;
	text-align: center;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-o-transition: all 0.3s;
	-ms-transition: all 0.3s;
	transition: all 0.3s;
	-webkit-box-shadow: 0px 4px 24.03px 2.97px rgba(25, 38, 62, 0.05);
	box-shadow: 0px 4px 24.03px 2.97px rgba(25, 38, 62, 0.05);
	border-radius: 3px;
	position: relative;
	overflow: hidden;
  }
  
  @media (max-width: 1199px) {
	.pricing-section .pricing-grids .grid {
	  padding: 45px 35px 50px;
	}
  }
  
  @media (max-width: 991px) {
	.pricing-section .pricing-grids .grid {
	  width: calc(50% - 30px);
	}
  }
  
  @media (max-width: 767px) {
	.pricing-section .pricing-grids .grid {
	  width: calc(50% - 15px);
	  margin: 0 7px 15px;
	  padding: 35px 25px 40px;
	}
  }
  
  @media (max-width: 600px) {
	.pricing-section .pricing-grids .grid {
	  width: calc(100% - 15px);
	  float: none;
	}
  }
  
  .pricing-section .pricing-grids > .grid:nth-child(2) {
	-webkit-box-shadow: 0px 4px 40.94px 5.06px rgba(25, 38, 62, 0.12);
	box-shadow: 0px 4px 40.94px 5.06px rgba(25, 38, 62, 0.12);
  }
  
  .pricing-section .pricing-grids > .grid:nth-child(2) .theme-btn-s3 {
	background-color: rgba(252, 110, 54, 0.2);
  }
  
  .pricing-section .pricing-grids > .grid:nth-child(2) .theme-btn-s3:hover {
	background-color: #fc6e36;
  }
  
  .pricing-section .hilight-tag {
	background-color: #fc6e36;
	font-size: 14px;
	font-size: 0.93333rem;
	font-weight: 600;
	padding: 4px 50px 5px 30px;
	color: #fff;
	line-height: 26px;
	position: absolute;
	top: 15px;
	left: -30px;
	z-index: 1;
	-ms-transform: rotate(-38deg);
	transform: rotate(-38deg);
	-webkit-transform: rotate(-38deg);
  }
  
  .pricing-section .pricing-header h5 {
	font-size: 20px;
	font-size: 1.33333rem;
	font-weight: 600;
	margin: 0 0 0.5em;
  }
  
  .pricing-section .pricing-header i {
	display: block;
  }
  
  .pricing-section .pricing-header .fi:before {
	font-size: 70px;
	font-size: 4.66667rem;
	color: #233d62;
  }
  
  .pricing-section .pricing-header h1 {
	font-size: 60px;
	font-size: 4rem;
	margin: 0;
	position: relative;
	display: inline-block;
  }
  
  .pricing-section .pricing-header h1 span {
	font-size: 24px;
	font-size: 1.6rem;
	position: absolute;
	left: -12px;
	top: 10px;
  }
  
  .pricing-section .pricing-header p {
	font-size: 13px;
	font-size: 0.86667rem;
	text-transform: uppercase;
	margin: 0;
  }
  
  .pricing-section .pricing-body {
	margin-top: 25px;
  }
  
  .pricing-section .pricing-body ul {
	margin-bottom: 35px;
  }
  
  .pricing-section .pricing-body li {
	font-size: 14px;
	font-size: 0.93333rem;
  }
  
  .pricing-section .pricing-body ul > li + li {
	border-top: 1px solid #efefef;
	padding-top: 10px;
	margin-top: 10px;
  }