.why-choose-us-section .why-choose-grids .grid {
  padding: 40px 35px 40px 130px;
  -webkit-box-shadow: 0px 4px 25.81px 3.19px rgba(35, 61, 98, 0.06);
  box-shadow: 0px 4px 25.81px 3.19px rgba(35, 61, 98, 0.06);
  position: relative;
}

@media (max-width: 767px) {
  .why-choose-us-section .why-choose-grids .grid {
    padding: 35px 25px;
  }
}

.why-choose-us-section .why-choose-grids > .grid + .grid {
  margin-top: 20px;
}

.why-choose-us-section .why-choose-grids > .grid:first-child,
.why-choose-us-section .why-choose-grids > .grid:last-child {
  position: relative;
  left: -50px;
}

@media (max-width: 991px) {
  .why-choose-us-section .why-choose-grids > .grid:first-child,
  .why-choose-us-section .why-choose-grids > .grid:last-child {
    left: 0;
  }
}

.why-choose-us-section .why-choose-grids > .grid:nth-child(2) .icon {
  background-color: #e8e5fc;
}

.why-choose-us-section .why-choose-grids > .grid:nth-child(2) .fi:before {
  color: #644eee;
}

.why-choose-us-section .why-choose-grids > .grid:nth-child(3) .icon {
  background-color: #d9eedc;
}

.why-choose-us-section .why-choose-grids > .grid:nth-child(3) .fi:before {
  color: #018d11;
}

.why-choose-us-section .why-choose-grids .icon {
  background-color: rgba(252, 110, 54, 0.1);
  width: 70px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  position: absolute;
  left: 35px;
  top: 40px;
  border-radius: 50%;
}

@media (max-width: 767px) {
  .why-choose-us-section .why-choose-grids .icon {
    position: relative;
    left: 0;
    top: 0;
    margin-bottom: 15px;
  }
}

.why-choose-us-section .why-choose-grids .fi:before {
  font-size: 30px;
  font-size: 2rem;
  color: #fc6e36;
}

.why-choose-us-section .why-choose-grids h3 {
  font-size: 18px;
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0 0 0.5em;
}

.why-choose-us-section .why-choose-grids p {
  margin-bottom: 0;
}