.about-us-section-s2 {
  background: url("../../images/about-s2-bg.png") center top/cover no-repeat
    local;
  padding-bottom: 70px;
}

@media (max-width: 991px) {
  .about-us-section-s2 {
    padding-bottom: 90px;
  }
}

@media (max-width: 767px) {
  .about-us-section-s2 {
    padding-bottom: 80px;
  }
}

@media screen and (min-width: 1200px) {
  .about-us-section-s2 .section-title-s5,
  .about-us-section-s2 .section-title-s6,
  .about-us-section-s2 .section-title-s7,
  .about-us-section-s2 .section-title-s8 {
    margin-top: 50px;
    padding-left: 25px;
  }
  .about-us-section-s2 .about-details {
    padding-left: 25px;
  }
}

@media (max-width: 991px) {
  .about-us-section-s2 .section-title-s5,
  .about-us-section-s2 .section-title-s6,
  .about-us-section-s2 .section-title-s7,
  .about-us-section-s2 .section-title-s8 {
    margin-top: 50px;
  }
}

.about-us-section-s2 .about-details h5 {
  font-size: 18px;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.6em;
  margin: 0 0 1em;
}

.about-us-section-s2 .about-details p {
  margin-bottom: 2.5em;
}

.about-us-section-s2 .feature-grids {
  margin: 0 -15px;
}

@media (max-width: 767px) {
  .about-us-section-s2 .feature-grids {
    margin: 0 -7px;
  }
}

.about-us-section-s2 .feature-grids .grid {
  background-color: #fff;
  width: calc(33% - 30px);
  float: left;
  margin: 0 15px 30px;
  padding: 50px 25px;
  text-align: center;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -ms-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-box-shadow: 0px 4px 21.36px 2.64px rgba(25, 38, 62, 0.06);
  box-shadow: 0px 4px 21.36px 2.64px rgba(25, 38, 62, 0.06);
}

@media (max-width: 1199px) {
  .about-us-section-s2 .feature-grids .grid {
    padding: 45px 25px;
  }
}

@media (max-width: 991px) {
  .about-us-section-s2 .feature-grids .grid {
    width: calc(50% - 30px);
  }
}

@media (max-width: 767px) {
  .about-us-section-s2 .feature-grids .grid {
    width: calc(50% - 15px);
    margin: 0 7px 15px;
    padding: 35px 20px;
  }
}

@media (max-width: 600px) {
  .about-us-section-s2 .feature-grids .grid {
    width: calc(100% - 15px);
    float: none;
  }
}

.about-us-section-s2 .feature-grids > .grid:nth-child(2) .fi {
  background-color: #2ecc0a;
}

.about-us-section-s2 .feature-grids > .grid:nth-child(3) .fi {
  background-color: #7633ff;
}

.about-us-section-s2 .feature-grids > .grid:nth-child(2) {
  position: relative;
  top: 50px;
}

@media (max-width: 600px) {
  .about-us-section-s2 .feature-grids > .grid:nth-child(2) {
    top: 0;
  }
}

.about-us-section-s2 .grid .fi {
  background-color: #fc6e36;
  width: 75px;
  height: 75px;
  line-height: 75px;
  text-align: center;
  margin: 0 auto;
  border-radius: 50%;
  display: block;
}

.about-us-section-s2 .grid .fi:before {
  font-size: 35px;
  font-size: 2.33333rem;
  color: #fff;
}

@media (max-width: 767px) {
  .about-us-section-s2 .grid .fi:before {
    font-size: 35px;
    font-size: 2.33333rem;
  }
}

.about-us-section-s2 .grid h3 {
  font-size: 18px;
  font-size: 1.2rem;
  font-weight: 600;
  margin: 1.3em 0 0.7em;
}

@media (max-width: 11999px) {
  .about-us-section-s2 .grid h3 {
    font-size: 16px;
    font-size: 1.06667rem;
  }
}

.about-us-section-s2 .grid p {
  font-size: 14px;
  font-size: 0.93333rem;
  margin: 0;
}
