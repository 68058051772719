.why-choose-us-s1 {
  padding-top: 0 !important;
}
.why-choose-us-s1.why-choose-us-s5 {
  padding-top: 100px !important;
}

@media (max-width: 991px) {
  .why-choose-us-s1 .why-choose-grids {
    margin-bottom: 60px;
  }
}

.why-choose-us-s1 .why-choose-grids .grid {
  padding: 0 0 0 90px;
  position: relative;
}

.why-choose-us-s1 .why-choose-grids > .grid + .grid {
  margin-top: 50px;
}

.why-choose-us-s1 .why-choose-grids > .grid:nth-child(2) .icon {
  background-color: #e8e5fc;
}

.why-choose-us-s1 .why-choose-grids > .grid:nth-child(2) .fi:before {
  color: #644eee;
}

.why-choose-us-s1 .why-choose-grids > .grid:nth-child(3) .icon {
  background-color: #d9eedc;
}

.why-choose-us-s1 .why-choose-grids > .grid:nth-child(3) .fi:before {
  color: #018d11;
}

.why-choose-us-s1 .why-choose-grids .icon {
  background-color: rgba(252, 110, 54, 0.1);
  width: 65px;
  height: 65px;
  line-height: 65px;
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 50%;
}

.why-choose-us-s1 .why-choose-grids .fi:before {
  font-size: 30px;
  font-size: 2rem;
  color: #fc6e36;
}

.why-choose-us-s1 .why-choose-grids h3 {
  font-size: 18px;
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0 0 0.5em;
}

@media (max-width: 767px) {
  .why-choose-us-s1 .why-choose-grids h3 {
    font-size: 16px;
    font-size: 1.06667rem;
  }
}

.why-choose-us-s1 .why-choose-grids p {
  margin-bottom: 0;
}

.about-pg-why-choose-us {
  padding-top: 100px;
}

@media (max-width: 991px) {
  .about-pg-why-choose-us {
    padding-top: 90px;
  }
}

@media (max-width: 767px) {
  .about-pg-why-choose-us {
    padding-top: 80px;
  }
}

.why-choose-us-s1 .why-choose-grids .icon {
  width: 40px;
  height: 40px;
  line-height: 40px;
  position: relative;
  margin-right: 25px;
}

.why-choose-us-s1 .why-choose-grids .grid {
  padding: 0;
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.why-choose-us-s1 .why-choose-grids > .grid + .grid {
  margin: 20px 0;
}
