.tips-tricks-section {
	background: url("../../images/tips-tricks-bg.png") left top/100% no-repeat local;
  }
  
  @media (max-width: 991px) {
	.tips-tricks-section .tips-trick-grids {
	  margin-top: 50px;
	}
  }
  
  .tips-tricks-section .tips-trick-grids > .grid + .grid {
	margin-top: 10px;
  }
  
  .tips-tricks-section .grid {
	background-color: #fff;
	padding: 40px;
	-webkit-box-shadow: 0px 4px 21.36px 2.64px rgba(25, 38, 62, 0.06);
	box-shadow: 0px 4px 21.36px 2.64px rgba(25, 38, 62, 0.06);
	position: relative;
  }
  
  @media (max-width: 767px) {
	.tips-tricks-section .grid {
	  padding: 30px;
	}
  }
  
  .tips-tricks-section .grid h4 {
	font-size: 16px;
	font-size: 1.06667rem;
	font-weight: 600;
	margin: 0 0 1em;
	padding-left: 45px;
  }
  
  .tips-tricks-section .grid .icon {
	background-color: rgba(252, 110, 54, 0.15);
	width: 32px;
	height: 32px;
	line-height: 28px;
	text-align: center;
	border-radius: 32px;
	position: absolute;
	left: 40px;
	top: 35px;
  }
  
  @media (max-width: 767px) {
	.tips-tricks-section .grid .icon {
	  left: 30px;
	  top: 25px;
	}
  }
  
  .tips-tricks-section .grid p {
	font-size: 14px;
	font-size: 0.93333rem;
	margin: 0;
  }
  