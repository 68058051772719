.slick-initialized .slick-slide {
  text-align: center;
}

.primary {
  color: #fc6e36;
}

.max-3 {
  max-width: 300px;
}
