.portfolio-section {
	padding-bottom: 70px;
  }
  
  @media (max-width: 991px) {
	.portfolio-section {
	  padding-bottom: 60px;
	}
  }
  
  @media (max-width: 767px) {
	.portfolio-section {
	  padding-bottom: 50px;
	}
  }
  
  .portfolio-section .portfolio-grids {
	margin: 0 -15px;
  }
  
  @media (max-width: 767px) {
	.portfolio-section .portfolio-grids {
	  margin: 0 -7px;
	}
  }
  
  .portfolio-section .portfolio-grids .grid {
	width: calc(33.33% - 30px);
	float: left;
	margin: 0 15px 30px;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-o-transition: all 0.3s;
	-ms-transition: all 0.3s;
	transition: all 0.3s;
	position: relative;
	overflow: hidden;
  }
  
  @media (max-width: 991px) {
	.portfolio-section .portfolio-grids .grid {
	  width: calc(50% - 30px);
	}
  }
  
  @media (max-width: 767px) {
	.portfolio-section .portfolio-grids .grid {
	  width: calc(50% - 15px);
	  margin: 0 7px 30px;
	}
  }
  
  @media (max-width: 600px) {
	.portfolio-section .portfolio-grids .grid {
	  width: calc(100% - 15px);
	  float: none;
	}
  }
  
  .portfolio-section .grid .details {
	background-color: rgba(29, 65, 170, 0.9);
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: -30%;
	text-align: center;
	display: table;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	transition: all 0.5s;
  }
  
  .portfolio-section .grid:hover .details {
	opacity: 1;
	visibility: visible;
	top: 0;
  }
  
  .portfolio-section .grid .content {
	display: table-cell;
	vertical-align: middle;
  }
  
  .portfolio-section .grid .content h3 {
	font-size: 18px;
	font-size: 1.2rem;
	margin: 0 0 0.4em;
  }
  
  .portfolio-section .grid .content h3 a {
	color: #fff;
  }
  
  .portfolio-section .grid .content p {
	color: #fc6e36;
  }