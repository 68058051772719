.team-section {
	padding-bottom: 70px;
  }
  
  @media (max-width: 991px) {
	.team-section {
	  padding-bottom: 30px;
	}
  }
  
  @media (max-width: 767px) {
	.team-section {
	  padding-bottom: 30px;
	}
  }
  
  .team-section .team-grids {
	margin: 0 -15px;
  }
  
  @media (max-width: 767px) {
	.team-section .team-grids {
	  margin: 0 -7px;
	}
  }
  
  .team-section .team-grids .grid {
	width: calc(25% - 30px);
	float: left;
	margin: 0 15px 30px;
	text-align: center;
  }
  
  @media (max-width: 991px) {
	.team-section .team-grids .grid {
	  width: calc(50% - 30px);
	  margin: 0 15px 60px;
	}
  }
  
  @media (max-width: 767px) {
	.team-section .team-grids .grid {
	  width: calc(50% - 15px);
	  margin: 0 7px 50px;
	}
  }
  
  @media (max-width: 550px) {
	.team-section .team-grids .grid {
	  width: calc(100% - 15px);
	  float: none;
	}
  }
  
  .team-section .details {
	padding-top: 25px;
  }
  
  .team-section .details h4 {
	font-size: 18px;
	font-size: 1.2rem;
	margin: 0 0 0.3em;
  }
  
  @media (max-width: 767px) {
	.team-section .details h4 {
	  font-size: 16px;
	  font-size: 1.06667rem;
	}
  }
  
  .team-section .details p {
	font-size: 14px;
	font-size: 0.93333rem;
	margin: 0 0 0.5em;
  }
  
  @media (max-width: 767px) {
	.team-section .details p {
	  font-size: 13px;
	  font-size: 0.86667rem;
	}
  }
  
  .team-section .details ul {
	display: inline-block;
  }
  
  .team-section .details ul li {
	float: left;
  }
  
  .team-section .details ul > li + li {
	margin-left: 15px;
  }
  
  .team-section .details a {
	font-size: 13px;
	color: #687693;
  }
  
  .team-section .details a:hover {
	color: #fc6e36;
  }
  